import { useState } from 'react';
// import { login } from "api/apiCalls";
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowBack,
  EmailOutlined,
  LockOutlined,
  VisibilityOff,
  Visibility,
} from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alerta,
  BoxInput,
  Button,
  Container,
  Desc,
  ForgotPass,
  Form,
  Icon,
  IconPass,
  Input,
  Links,
  Register,
  Title,
  Wrapper,
} from './LoginStyles';
import { loginUser } from 'features/user/userThunks';
import { alertError, alertToast } from 'components/alerts/SweetAlert';

const Login = () => {
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);

  const login = async (valores) => {
    const res = await dispatch(loginUser(valores));

    if (res.error) {
      alertError('Email o contraseña incorrectos');
    }
  };

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('El email es obligatorio.'),
      password: Yup.string().required('La contraseña es obligatoria.'),
    }),
    onSubmit: (valores) => {
      login(valores);
    },
  });

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <Container>
      <Wrapper>
        <Links to='/' className='link'>
          <ArrowBack />
        </Links>
        <Title>Iniciar Sesión</Title>

        <Form onSubmit={formik.handleSubmit}>
          <BoxInput>
            <Icon>
              <EmailOutlined />
            </Icon>
            <Input
              type='email'
              id='email'
              placeholder='Ingrese su correo electrónico'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.email && formik.errors.email ? (
            <Alerta>{formik.errors.email}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <LockOutlined />
            </Icon>
            <Input
              type={show ? 'text' : 'password'}
              id='password'
              placeholder='Ingrese su contraseña'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <IconPass onClick={handleClick}>
              {show ? <Visibility /> : <VisibilityOff />}
            </IconPass>
          </BoxInput>
          {formik.touched.password && formik.errors.password ? (
            <Alerta>{formik.errors.password}</Alerta>
          ) : null}

          <Button type='submit' disabled={isFetching}>
            Iniciar sesión
          </Button>
          {error && <Alerta>{error}</Alerta>}
          <ForgotPass>
            <Links
              to='/forgot-password'
              className='link'
              style={{ color: '#004aad', margin: '5px auto' }}
            >
              ¿Olvidaste tu contraseña?
            </Links>
          </ForgotPass>
          <Register>
            <Desc>¿No tienes cuenta aún? </Desc>
            <Links
              to='/registrarse'
              // className="link"
              style={{ color: '#004aad' }}
            >
              CREA UNA CUENTA
            </Links>
          </Register>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Login;
