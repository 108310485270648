import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  Span,
  Table,
  TableContainer,
  Title,
  TitleContainer,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { userRequest } from 'config/clienteAxios';
import { Link } from 'react-router-dom';
import { currencyFormat } from 'utils/data-conversion';
import { Edit } from '@material-ui/icons';
import { DataGridTable } from 'utils/data-grid-table';

const OrderList = () => {
  const [data, setData] = useState([]);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  useEffect(() => {
    const getOrders = async () => {
      const res = await userRequest.get('/ordenes', {
        headers: { token: accessToken },
      });
      setData(res.data.orders);
    };
    getOrders();
  }, []);

  const columns = [
    {
      field: 'orderNum',
      headerName: 'N°',
      width: 100,
    },
    {
      field: 'userId',
      headerName: 'Cliente',
      width: 200,
      renderCell: (params) => {
        return params.row?.userId ? (
          <div>
            {params.row.User.name} {params.row.User.lastName}
          </div>
        ) : (
          <div>
            {params.row.userInvite.name} {params.row.userInvite.lastName}
          </div>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Fecha',
      width: 130,
    },
    {
      field: 'hour',
      headerName: 'Hora',
      width: 120,
    },
    {
      field: 'amount',
      headerName: 'Total',
      width: 150,
      renderCell: (params) => {
        return <div>{currencyFormat(params.row.total)}</div>;
      },
    },
    {
      field: 'status',
      headerName: 'Estado',
      width: 150,
    },
    {
      field: 'action',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={'/viewOrden/' + params.row.id}
              style={{
                color: 'blue',
                marginRight: '10px',
                textDecoration: 'underline',
              }}
            >
              Ver detalle
            </Link>
            <Link to={'/editOrden/' + params.row.id}>
              <Button
                bgColor='alternative'
                padding='5px 7px'
                margin='0 10px 0 0'
                color='#fff'
              >
                <Edit />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <TitleContainer>
            <Title>
              Ordenes <Span>({data.length} ordenes)</Span>
            </Title>
          </TitleContainer>

          <TableContainer>
            <Table>
              <DataGridTable data={data} columns={columns} />
            </Table>
          </TableContainer>
        </Wrapper>
      </div>
    </>
  );
};

export default OrderList;
