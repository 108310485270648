import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Visibility } from '@material-ui/icons';
import { userRequest } from 'config/clienteAxios';
import { mobile } from 'styles/responsive';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Container = styled.div`
  flex: 1;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  padding: 20px;
  margin-right: 20px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    ${mobile({ fontSize: '20px' })}
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    ${mobile({ fontSize: '20px' })}

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
      div {
        display: flex;
        flex-direction: column;
        font-weight: 600;
      }
      button {
        display: flex;
        align-items: center;
        border: none;
        border-radius: 10px;
        padding: 10px;
        background-color: #eeeef7;
        color: #555;
        cursor: pointer;
      }
    }
  }

  ${mobile({ width: '295px', marginBottom: '10px', marginRight: '0' })}
`;

const WidgetSm = () => {
  const [users, setUsers] = useState([]);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));
  const navigate = useHistory();

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get('/usuarios/?new=true', {
          headers: { token: accessToken },
        });
        setUsers(res.data.users);
      } catch (error) {}
    };
    getUsers();
  }, [accessToken]);

  return (
    <Container>
      <h3>Nuevos miembros</h3>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            <img
              src={
                user.img ||
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
              }
              alt=''
            />
            <div>
              <span>{user.name}</span>
            </div>
            <button onClick={() => navigate.push('/viewUsuario/' + user.id)}>
              <Visibility style={{ fontSize: '16px', marginRight: '5px' }} />
              Mostrar
            </button>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default WidgetSm;
