import { useEffect, useState } from 'react';
import Footer from 'components/footer/Footer';
import { Spinner2 } from 'components/spinner/Spinner';
import { alertToast } from 'components/alerts/SweetAlert';

import { Link, useHistory } from 'react-router-dom';
import { Add, Remove, Delete } from '@material-ui/icons';
import { publicRequest, userRequest } from 'config/clienteAxios';
import { currencyFormat } from 'utils/data-conversion';
import { MostrarDescuento } from 'utils/descuento';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProduct,
  updateQuantity,
  updateQuantityDec,
} from 'features/cart/cartSlice';
import Layout from 'components/layouts/Layout';
import {
  Bottom,
  Box,
  Btn,
  Button,
  ButtonDelete,
  CodigoP,
  Details,
  Hr,
  Image,
  Info,
  Input,
  Links,
  PriceDetail,
  Product,
  ProductAmount,
  ProductAmountContainer,
  ProductColor,
  ProductDetail,
  ProductId,
  ProductName,
  ProductPrice,
  ProductSize,
  Summary,
  SummaryItem,
  SummaryItemPrice,
  SummaryItemText,
  SummaryTitle,
  Title,
  Top,
  TopButton,
  TopText,
  TopTexts,
  Wrapper,
} from './CartStyles';
import { API_URL_IMG } from 'utils/constants';

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [cupon, setCupon] = useState('');
  const [data, setData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState(true);
  const [formaPago, setFormaPago] = useState('');
  const [formaEnvio, setFormaEnvio] = useState('');
  const [cargando, setCargando] = useState(false);

  const [envio, setEnvio] = useState(0);
  const [adicional, setAdicional] = useState(0);
  const [monto, setMonto] = useState(0);

  // const [pedido, setPedido] = useState(0);
  // const [importe, setImporte] = useState(0);
  // const [tarjeta, setTarjeta] = useState(false);

  // Orden
  const userId = useSelector((state) => state.user.currentUser?._id);
  const cartProduct = useSelector((state) => state.cart.products);
  const dataOrder = { id: userId, productos: cartProduct };
  // const [dataNew] = useState(dataOrder);
  const history = useHistory();

  const handleDelete = (id, price, quantity) => {
    dispatch(deleteProduct({ id, price, quantity }));
    alertToast('Producto eliminado del carrito');
  };

  const handleChange = (e) => {
    setCupon((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleClickCupon = (e) => {
    e.preventDefault();

    const getData = async () => {
      try {
        const res = await userRequest.post('/cupon/validar-cupon', cupon);

        if (res.data.coupon) {
          setData(res.data.coupon);
          setSuccess(res.data.success);
          setError(res.data.success);
          setMsg(res.data.msg);
        } else {
          setData([]);
          setError(res.data.success);
          setMsg(res.data.msg);
        }
      } catch (error) {}
    };
    getData();
  };

  const handleCalculo = (total) => {
    if (data) {
      const { type, descount } = data;
      if (type === 'porcentaje') {
        const des = total * descount;
        const res = total - des;
        return res; // .toFixed(2).toString().replace(".", ",")
      }
      if (type === 'importe') {
        const res = total - descount;
        return res; // .toFixed(2).toString().replace(".", ",")
      }
    }
    return total; // .toFixed(2).toString().replace(".", ",")
  };

  // ===== INCREMENTAR CANTIDAD DEL PRODUCTO EN CARRITO =====
  const handleQuantityInc = (id, producto, price) => {
    let cant = 1;
    const product = { ...producto };

    if (
      product.quantity < stock(product.details, product.brand, product.model)
    ) {
      product.quantity = product.quantity + 1;
      dispatch(updateQuantity({ id, product, price, cant }));
    } else {
      return;
    }
  };

  // ===== DECREMENTAR CANTIDAD DEL PRODUCTO EN CARRITO =====
  const handleQuantityDec = (id, producto, price) => {
    let cant = 1;
    const product = { ...producto };
    if (product.quantity > 1) {
      product.quantity = product.quantity - 1;
      dispatch(updateQuantityDec({ id, product, price, cant }));
    }
  };

  // ===== REALIZAR LA COMPRA AL DAR CLICK EN COMPRAR =====
  const handleClick = async (e) => {
    e.preventDefault();

    if (user && formaPago === '' && formaEnvio === '') {
      return;
    }

    try {
      // Usuario que se REGISTRO
      if (user && cartProduct.length > 0 && formaPago && formaEnvio) {
        setCargando(true);
        let status = 'Pendiente';
        let des = data?.length === 0 ? 0 : data;

        // const resultEnvio = cart.total >= monto ? 0 : envio;
        const resultEnvio =
          formaEnvio === 'retiradaentienda'
            ? 0
            : cart.total >= monto
            ? 0
            : envio;

        const resultado = await userRequest.post(`/ordenes/${status}`, {
          dataOrder,
          des,
          paymentForm: formaPago,
          shipmentForm: formaEnvio,
          shipment: resultEnvio,
          additional: adicional,
          amount: monto,
          dataUser: user,
        });
        // setCargando(false);

        const ord = resultado.data.newOrder;
        if (
          ord.paymentForm === 'transferencia' ||
          ord.paymentForm === 'contrareembolso'
        ) {
          setCargando(false);
          history.push('/success');
        } else {
          const res = await userRequest.post('/checkout/pago', {
            productos: cart.products,
            des,
            formaPago,
            formaEnvio,
            envio: resultEnvio,
            adicional,
            monto,
          });
          const { init_point } = res.data;
          setCargando(false);
          window.location.href = `${init_point}`;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ===== DEVULEVE EL STOCK DE UN PRODUCTO SELECCIONADO =====
  const stock = (details, brand, model) => {
    const res = details.filter((i) =>
      i.brand === brand && i.model === model ? i.stock : null
    );
    return Number(res[0].stock);
  };

  const getInfo = async () => {
    try {
      const res = await publicRequest.get('/infotienda');
      setEnvio(Number(res.data[0].costos[0].envio) || 4.99);
      setAdicional(Number(res.data[0].costos[0].adicional) || 5.0);
      setMonto(Number(res.data[0].costos[0].monto) || 50.0);
    } catch (error) {}
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Layout title='Carrito de Compras'>
      <Wrapper>
        <Title>Mi carrito</Title>
        <Top>
          <TopButton>
            <Links to='/lista-productos'>Seguir comprando</Links>
          </TopButton>
          <TopTexts>
            <TopText>
              Productos en el carrito (<b>{cart.quantity}</b>)
            </TopText>
          </TopTexts>
        </Top>
        <Bottom>
          <Info>
            {cart.products.length >= 1 ? (
              cart.products.map((product, index) => (
                <Product key={index}>
                  <ProductDetail>
                    <Link to={`/producto/${product.slug}`}>
                      <Image src={API_URL_IMG + product.img?.[0]} />
                    </Link>

                    <Details>
                      <ProductName>
                        <b>{product.title}</b>
                      </ProductName>
                      <ProductId>
                        <b>Cod.</b> {product.cod}
                      </ProductId>
                      {product.details[0].measures ? (
                        <ProductSize>
                          <b>Medidas:</b> {product.details[0].measures}
                        </ProductSize>
                      ) : null}
                      {product.details[0].color ? (
                        <ProductColor>
                          <b>Color:</b> {product.details[0].color}
                        </ProductColor>
                      ) : null}
                    </Details>
                  </ProductDetail>
                  <p style={{ fontSize: '12px', color: 'red' }}>
                    {product.quantity ===
                    stock(product.details, product.brand, product.model)
                      ? '*No queda mas stock del producto'
                      : null}
                  </p>
                  <Box>
                    <PriceDetail>
                      <ProductPrice>
                        {currencyFormat(product.price * product.quantity)}
                      </ProductPrice>
                    </PriceDetail>
                    <ProductAmountContainer>
                      <Remove
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleQuantityDec(product._id, product, product.price)
                        }
                      />
                      <ProductAmount>{product.quantity}</ProductAmount>
                      <Add
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleQuantityInc(product._id, product, product.price)
                        }
                      />
                    </ProductAmountContainer>
                    <ButtonDelete
                      onClick={() =>
                        handleDelete(
                          product._id,
                          product.price,
                          product.quantity
                        )
                      }
                    >
                      <Delete />
                    </ButtonDelete>
                  </Box>
                </Product>
              ))
            ) : (
              <p style={{ textAlign: 'center', margin: '200px 0 325px' }}>
                No hay productos en el carrito
              </p>
            )}
            {/* <Hr /> */}
          </Info>
          <Summary>
            <SummaryTitle>Resumen de compra</SummaryTitle>
            <SummaryItem>
              <SummaryItemText>Subtotal</SummaryItemText>
              <SummaryItemPrice>
                {currencyFormat(handleCalculo(cart.total))}
              </SummaryItemPrice>
            </SummaryItem>
            {success && (
              <SummaryItem>
                <SummaryItemText>Cupón Descuento</SummaryItemText>
                <SummaryItemPrice>
                  -
                  {success &&
                    currencyFormat(MostrarDescuento(cart.total, data))}
                </SummaryItemPrice>
              </SummaryItem>
            )}
            {((formaPago === 'transferencia' && formaEnvio === 'envio') ||
              (formaPago === 'tarjeta' && formaEnvio === 'envio')) && (
              <SummaryItem>
                <SummaryItemText>Envio</SummaryItemText>
                <SummaryItemPrice>
                  {cart.total >= monto
                    ? currencyFormat(0)
                    : currencyFormat(envio)}
                </SummaryItemPrice>
              </SummaryItem>
            )}
            {formaPago === 'contrareembolso' && formaEnvio === 'envio' && (
              <>
                <SummaryItem>
                  <SummaryItemText>Envio</SummaryItemText>
                  <SummaryItemPrice>
                    {cart.total >= monto
                      ? currencyFormat(0)
                      : currencyFormat(envio)}
                  </SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Adic. Contrareembolso</SummaryItemText>
                  <SummaryItemPrice>
                    {currencyFormat(adicional)}
                  </SummaryItemPrice>
                </SummaryItem>
              </>
            )}
            {formaPago === 'contrareembolso' &&
              formaEnvio === 'retiradaentienda' && (
                <SummaryItem>
                  <SummaryItemText>Adic. Contrareembolso</SummaryItemText>
                  <SummaryItemPrice>
                    {currencyFormat(adicional)}
                  </SummaryItemPrice>
                </SummaryItem>
              )}
            <Hr />
            <SummaryItem type='total'>
              <SummaryItemText>Total</SummaryItemText>
              <SummaryItemPrice>
                {formaPago && formaEnvio === 'envio' ? (
                  <>
                    {formaPago === 'transferencia' ||
                    formaPago === 'tarjeta' ? (
                      <>
                        {cart.total >= monto
                          ? currencyFormat(handleCalculo(cart.total))
                          : currencyFormat(handleCalculo(cart.total) + envio)}
                      </>
                    ) : (
                      <>
                        {cart.total >= monto
                          ? currencyFormat(
                              handleCalculo(cart.total) + adicional
                            )
                          : currencyFormat(
                              handleCalculo(cart.total) + envio + adicional
                            )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {formaEnvio === 'retiradaentienda' &&
                    formaPago === 'contrareembolso'
                      ? currencyFormat(handleCalculo(cart.total) + adicional)
                      : currencyFormat(handleCalculo(cart.total))}
                  </>
                )}
              </SummaryItemPrice>
            </SummaryItem>
            <Hr />
            {user ? (
              <>
                <div>
                  <div style={{ margin: '10px 0 5px', fontWeight: 'bolder' }}>
                    Formas de Entrega
                  </div>
                  <div style={{ fontSize: '14px', marginBottom: '10px' }}>
                    <label style={{ marginRight: '15px' }}>
                      <input
                        type='radio'
                        name='envio'
                        value='envio'
                        checked={formaEnvio === 'envio'}
                        onChange={(e) => setFormaEnvio(e.target.value)}
                        style={{ accentColor: '#F50057' }}
                      />{' '}
                      Envio
                    </label>
                    <label>
                      <input
                        type='radio'
                        name='retiradaentienda'
                        value='retiradaentienda'
                        checked={formaEnvio === 'retiradaentienda'}
                        onChange={(e) => setFormaEnvio(e.target.value)}
                        style={{ accentColor: '#F50057' }}
                      />{' '}
                      Retirada en tienda
                    </label>
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: '5px', fontWeight: 'bolder' }}>
                    Formas de Pago
                  </div>
                  <div style={{ fontSize: '14px', marginBottom: '20px' }}>
                    <label
                      style={{
                        display: 'flex',
                        marginBottom: '6px',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        type='radio'
                        name='pago3'
                        value='tarjeta'
                        checked={formaPago === 'tarjeta'}
                        onChange={(e) => setFormaPago(e.target.value)}
                        style={{ accentColor: '#F50057' }}
                        disabled={formaEnvio ? false : true}
                      />{' '}
                      <span style={{ marginLeft: '5px' }}>Mercadopago</span>
                      {/* <img src={ImgPago} alt='Forma de Pago' width="70px" style={{ marginLeft: "6px" }} /> */}
                    </label>
                    <label
                      style={{
                        display: 'flex',
                        marginBottom: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        type='radio'
                        name='pago2'
                        value='contrareembolso'
                        checked={formaPago === 'contrareembolso'}
                        onChange={(e) => setFormaPago(e.target.value)}
                        disabled={formaEnvio ? false : true}
                        style={{ accentColor: '#F50057' }}
                      />{' '}
                      <span style={{ marginLeft: '5px' }}>Contrareembolso</span>
                    </label>
                    {/* <label style={{ display: "flex", marginBottom: "10px", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="pago1"
                        value="transferencia"
                        checked={formaPago === "transferencia"}
                        onChange={(e) => setFormaPago(e.target.value)}
                        disabled={formaEnvio ? false : true}
                        style={{ accentColor: "#F50057"}}
                      />{" "}
                      <span style={{ marginLeft: "5px"}}>Transferencia/Bizum</span>
                    </label> */}
                  </div>
                </div>
                {!error && (
                  <p style={{ fontSize: '12px', color: 'red' }}>{msg}</p>
                )}
                <CodigoP>
                  <Input
                    type='text'
                    name='cupon'
                    placeholder='Código del cupón'
                    onChange={handleChange}
                  />
                  <Btn onClick={handleClickCupon}>Aplicar cupón</Btn>
                </CodigoP>

                {/* ================= COMPRA USUARIO REGISTRADO ================= */}
                <Button
                  type='submit'
                  onClick={handleClick}
                  style={{ marginBottom: '5px', textTransform: 'uppercase' }}
                  disabled={cargando ? true : false}
                >
                  {cargando ? <Spinner2 /> : 'COMPRAR'}
                </Button>
              </>
            ) : (
              <>
                <div>
                  <div style={{ margin: '10px 0 5px', fontWeight: 'bolder' }}>
                    Formas de Entrega
                  </div>
                  <div style={{ fontSize: '14px', marginBottom: '10px' }}>
                    <label style={{ marginRight: '15px' }}>
                      <input
                        type='radio'
                        name='envio'
                        value='envio'
                        checked={formaEnvio === 'envio'}
                        onChange={(e) => setFormaEnvio(e.target.value)}
                        style={{ accentColor: '#F50057' }}
                      />{' '}
                      Envio
                    </label>
                    <label>
                      <input
                        type='radio'
                        name='retiradaentienda'
                        value='retiradaentienda'
                        checked={formaEnvio === 'retiradaentienda'}
                        onChange={(e) => setFormaEnvio(e.target.value)}
                        style={{ accentColor: '#F50057' }}
                      />{' '}
                      Retirada en tienda
                    </label>
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: '5px', fontWeight: 'bolder' }}>
                    Formas de Pago
                  </div>
                  <div style={{ fontSize: '14px', marginBottom: '20px' }}>
                    <label
                      style={{
                        display: 'flex',
                        marginBottom: '6px',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        type='radio'
                        name='pago3'
                        value='tarjeta'
                        checked={formaPago === 'tarjeta'}
                        onChange={(e) => setFormaPago(e.target.value)}
                        style={{ accentColor: '#F50057' }}
                        disabled={formaEnvio ? false : true}
                      />{' '}
                      <span style={{ marginLeft: '5px' }}>Mercadopago</span>
                      {/* <img src={ImgPago} alt='Forma de Pago' width="70px" style={{ marginLeft: "6px" }} /> */}
                    </label>
                    <label
                      style={{
                        display: 'flex',
                        marginBottom: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        type='radio'
                        name='pago2'
                        value='contrareembolso'
                        checked={formaPago === 'contrareembolso'}
                        onChange={(e) => setFormaPago(e.target.value)}
                        disabled={formaEnvio ? false : true}
                        style={{ accentColor: '#F50057' }}
                      />{' '}
                      <span style={{ marginLeft: '5px' }}>Contrareembolso</span>
                    </label>
                    {/* <label style={{ display: "flex", marginBottom: "10px", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="pago1"
                        value="transferencia"
                        checked={formaPago === "transferencia"}
                        onChange={(e) => setFormaPago(e.target.value)}
                        disabled={formaEnvio ? false : true}
                        style={{ accentColor: "#F50057"}}
                      />{" "}
                      <span style={{ marginLeft: "5px"}}>Transferencia/Bizum</span>
                    </label> */}
                  </div>
                </div>
                {/* ================= COMPRA USUARIO INVITADO ================= */}
                <Link
                  to={{
                    pathname: '/compra',
                    state: {
                      formaEnvio,
                      formaPago,
                      envio: cart.total >= monto ? 0 : envio,
                      adicional,
                      monto,
                    },
                  }}
                >
                  <Button
                    type='button'
                    style={{ marginBottom: '5px', textTransform: 'uppercase' }}
                    disabled={
                      cartProduct.length > 0 && formaPago && formaPago
                        ? false
                        : true
                    }
                  >
                    COMPRAR COMO INVITADO
                  </Button>
                </Link>
              </>
            )}
          </Summary>
        </Bottom>
      </Wrapper>
      <Footer />
    </Layout>
  );
};

export default Cart;
