import { useEffect, useState } from 'react';
import Footer from 'components/footer/Footer';
import ProductSkeleton from './ProductSkeleton';
import { alertToast } from 'components/alerts/SweetAlert';

import {
  Add,
  Favorite,
  FavoriteBorder,
  Remove,
  ShoppingCartOutlined,
} from '@material-ui/icons';
import { useLocation } from 'react-router';
import { publicRequest } from 'config/clienteAxios';
import { addProduct } from 'features/cart/cartSlice';
import { addProductFav, deleteProductFav } from 'features/fav/favSlice';
import { useDispatch } from 'react-redux';
import { currencyFormat } from 'utils/data-conversion';
import { calcularDescuento } from 'utils/descuento';
import ProductsList from 'components/carrousel/categories/ProductsList';
import Layout from 'components/layouts/Layout';
import {
  AddContainer,
  Amount,
  AmountContainer,
  Box,
  BoxImages,
  Button,
  ButtonFav,
  Del,
  Desc,
  Filter,
  FilterContainer,
  FilterSize,
  FilterSizeOption,
  FilterTitle,
  ImageBig,
  ImageSmall,
  ImgContainer,
  InfoContainer,
  P,
  Price,
  Title,
  Title2,
  Wrapper,
} from './ProductStyles';
import { API_URL_IMG } from 'utils/constants';

const Product = () => {
  const location = useLocation(); // location me devuelve un objeto con el pathname: "/producto/id" en el cua debere extraer el id
  // const id = location.pathname.split('/')[2]; // Me devuelve el id
  const slug = location.pathname.split('/')[2]; // Me devuelve el id

  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [favCant, setFavCant] = useState(false);
  const [measures, setMeasures] = useState('');
  const [color, setColor] = useState('');
  const dispatch = useDispatch();
  const [images, setImages] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const getProductId = async () => {
      try {
        const res = await publicRequest.get('/productos/' + slug);
        setProduct(res.data.product);
      } catch (error) {}
    };
    getProductId();
  }, [slug]);

  const handleQuantity = (type) => {
    if (type === 'dec') {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      setQuantity(quantity + 1);
    }
  };

  const handleAddCart = () => {
    // color && size && (quantity <= product.inStock)

    if (!measures || !color) {
      setError(true);
      setMessage('*Debe seleccionar una marca y modelo');
      setTimeout(() => {
        setError(false);
      }, 2000);
      return;
    }

    if (measures && color && quantity <= stock) {
      // Actualizar carrito
      dispatch(
        // addProduct({ product, quantity, price: product.price * quantity });
        // addProduct({ ...product, quantity, color, size })

        addProduct({
          ...product,
          quantity,
          measures,
          color,
          price: calcularDescuento(product.price, product?.discount),
        })
      );

      alertToast('Producto agregado al carrito');
      setMeasures('');
      setColor('');
    } else {
      return;
    }
  };

  const handleAddFav = () => {
    setFavCant(true);
    dispatch(addProductFav({ ...product }));
  };
  const handleDeleteFav = (id) => {
    setFavCant(false);
    dispatch(deleteProductFav({ id }));
  };
  const handleChangeImage = (img) => {
    setImages(img);
  };

  const imagenes = product.img;
  let measuresData = product.details;
  let colorData = product.details;
  let stock = product.details;

  const delDuplicate = () => {
    let hash = {};
    measuresData = measuresData?.filter((o) =>
      hash[o.measures] ? false : (hash[o.measures] = true)
    );
    return JSON.stringify(measuresData);
  };
  delDuplicate();

  const stockTC = () => {
    stock = stock
      ?.filter((o) => o.measures === measures && o.color === color)
      .map((item) => Number(item?.stock));
  };
  stockTC();

  return (
    <Layout title='Detalles Producto'>
      {Object.keys(product).length === 0 ? (
        <ProductSkeleton />
      ) : (
        <Wrapper>
          <ImgContainer>
            <ImageBig
              src={images ? API_URL_IMG + images : API_URL_IMG + imagenes?.[0]}
              alt=''
              loading='lazy'
            />
            <BoxImages>
              {imagenes?.map((item, index) => (
                <div key={index}>
                  <ImageSmall
                    src={API_URL_IMG + item}
                    onClick={() => handleChangeImage(item)}
                    loading='lazy'
                  />
                </div>
              ))}
            </BoxImages>
          </ImgContainer>

          <InfoContainer>
            <p style={{ color: '#888888', fontWeight: '500' }}>
              {product.categories}
            </p>
            <Title>{product.title}</Title>
            <Desc>
              {stock ? (
                <span
                  style={{
                    backgroundColor: '#51a042',
                    color: '#fff',
                    borderRadius: '5px',
                    padding: '0 5px',
                    fontWeight: '500',
                  }}
                >
                  En Stock
                </span>
              ) : (
                'Sin Stock'
              )}{' '}
            </Desc>
            <P>
              {product?.discount > 1 && (
                <Del>{currencyFormat(product.price)}</Del>
              )}
            </P>
            <Price>
              {product.price && product?.discount > 1
                ? currencyFormat(
                    calcularDescuento(product.price, product?.discount)
                  )
                : currencyFormat(product.price)}
            </Price>
            {product?.discount > 1 && (
              <span
                style={{
                  marginLeft: '10px',
                  fontSize: '20px',
                  color: '#51a042',
                  fontWeight: '500',
                }}
              >
                -{product?.discount}%
              </span>
            )}
            <ButtonFav
              onClick={
                favCant ? () => handleDeleteFav(product._id) : handleAddFav
              }
            >
              {favCant ? (
                <Favorite style={{ width: '45px', color: 'red' }} />
              ) : (
                <FavoriteBorder style={{ width: '45px', color: 'gray' }} />
              )}
            </ButtonFav>

            <FilterContainer>
              <Filter>
                <FilterTitle>Medidas</FilterTitle>
                <FilterSize onChange={(e) => setMeasures(e.target.value)}>
                  <FilterSizeOption>Elegir</FilterSizeOption>
                  {measuresData?.map((i, index) => (
                    <FilterSizeOption key={index}>
                      {i.measures}
                    </FilterSizeOption>
                  ))}
                </FilterSize>
              </Filter>
              <Filter>
                <FilterTitle>Color</FilterTitle>
                <FilterSize onChange={(e) => setColor(e.target.value)}>
                  <FilterSizeOption>Elegir</FilterSizeOption>
                  {colorData?.map((c, index) => (
                    <FilterSizeOption key={index}>{c.color}</FilterSizeOption>
                  ))}
                </FilterSize>
              </Filter>
            </FilterContainer>

            <AddContainer>
              <AmountContainer>
                <Remove
                  style={{ cursor: 'pointer', padding: '8px' }}
                  onClick={() => handleQuantity('dec')}
                />
                <Amount>{quantity}</Amount>
                <Add
                  style={{ cursor: 'pointer', padding: '8px' }}
                  onClick={() => handleQuantity('inc')}
                />
              </AmountContainer>
              <Button onClick={handleAddCart}>
                <ShoppingCartOutlined />
                <p style={{ marginLeft: '5px', color: '#fff' }}>
                  Añadir al carrito
                </p>
              </Button>
            </AddContainer>

            {error && (
              <div style={{ display: 'block', margin: '10px' }}>
                <p style={{ fontSize: '14px', color: 'red' }}>{message}</p>
              </div>
            )}
            {measures && color && quantity > stock ? (
              <div style={{ display: 'block', margin: '10px' }}>
                <p style={{ fontSize: '14px', color: 'red' }}>
                  *No queda mas stock del producto
                </p>
              </div>
            ) : null}

            <div style={{ margin: '30px 0' }}>
              <b style={{ fontSize: '18px' }}>Descripción:</b>
              <p>{product.desc}</p>
            </div>
          </InfoContainer>
        </Wrapper>
      )}
      <Box>
        <Title2>Productos relacionados</Title2>
      </Box>
      <ProductsList category={product.categories} productId={product._id} />
      <Footer />
    </Layout>
  );
};

export default Product;
