import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { publicRequest, userRequest } from 'config/clienteAxios';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Error } from '@material-ui/icons';

const Costos = () => {
  const accessToken = JSON.parse(localStorage.getItem('token_user'));
  const [data, setData] = useState({
    costos: [],
  });

  const [cost, setCost] = useState({
    envio: '',
    adicional: '',
    monto: '',
  });

  const getInfo = async () => {
    try {
      const res = await publicRequest.get('/infotienda', {
        headers: { token: accessToken },
      });
      setData(res?.data.info[0]);
      setCost(res?.data.info[0].costs);
    } catch (error) {}
  };
  const handleCostosChange = (e) => {
    setCost((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const producto = { ...data, costs: cost };

    try {
      const updateInfo = async () => {
        const res = await userRequest.put(`/infotienda/${data.id}`, producto, {
          headers: { token: accessToken },
        });
        if (res.data.success) {
          alertSuccess(res.data.msg);
        }
      };
      updateInfo();
    } catch (error) {}
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />

        <Wrapper>
          <Title>Costos</Title>
          {data ? (
            <Form>
              <BoxInput>
                <Label>Envio</Label>
                <Input
                  name='envio'
                  type='number'
                  placeholder='Costo de envio del producto'
                  value={cost?.envio}
                  min={0}
                  onChange={handleCostosChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Adicional</Label>
                <Input
                  name='adicional'
                  type='number'
                  placeholder='Costo adicional del producto'
                  value={cost?.adicional}
                  min={0}
                  onChange={handleCostosChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Monto</Label>
                <Input
                  name='monto'
                  type='number'
                  placeholder='Monto a partir del cual el envio es gratis'
                  value={cost?.monto}
                  min={0}
                  onChange={handleCostosChange}
                />
              </BoxInput>

              <BoxButtons>
                <Button
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                  onClick={handleFormSubmit}
                >
                  Guardar
                </Button>
              </BoxButtons>
            </Form>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                marginTop: '0.5rem',
                color: 'red',
              }}
            >
              <Error />
              <p
                style={{
                  color: 'red',
                }}
              >
                Debe cargar primero la configuración para cargar 'Costos'
              </p>
            </div>
          )}
        </Wrapper>
      </div>
    </>
  );
};

export default Costos;
