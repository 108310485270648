import React from 'react';
import Footer from 'components/footer/Footer';

import { MailOutline, Phone, Room, WhatsApp } from '@material-ui/icons';
import { publicRequest } from 'config/clienteAxios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import Layout from 'components/layouts/Layout';
import {
  Alerta,
  Box,
  Button,
  ContactItem,
  Desc,
  FormContainer,
  Iframe,
  InfoContainer,
  Input,
  Label,
  TextArea,
  Title,
  Wrapper,
} from './ContactStyles';

const Contacto = () => {
  const info = useSelector((state) => state.info.tienda);

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      nombre: '',
      email: '',
      tel: '',
      mensaje: '',
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Solo se permiten letras para este campo.')
        .min(3, 'Tu nombre es demasiado corto.')
        .required('El campo es requerido.'),
      email: Yup.string()
        .email('El email no es valido')
        .required('El campo es requerido.'),
      tel: Yup.string()
        .matches(/^[0-9\s]+$/, 'Solo se permiten numeros para este campo.')
        .min(9, 'El telefono debe contener al menos 9 digitos.')
        .required('El campo es requerido'),
      mensaje: Yup.string()
        .required('El campo es requerido.')
        .max(150, 'Debe contener como maximo 150 caracteres.'),
    }),
    onSubmit: (valores, { resetForm }) => {
      FormContact(valores);
      resetForm({ valores: '' }); // Limpiar el formulario
    },
  });

  const FormContact = (inputs) => {
    const sendDataForm = async () => {
      await publicRequest.post('/email', inputs);
    };
    sendDataForm();
  };

  return (
    <Layout title='Contacto'>
      <Wrapper>
        <FormContainer onSubmit={formik.handleSubmit}>
          <Title>Contacto</Title>
          <Box>
            <Label htmlFor='nombre'>Nombre</Label>
            <Input
              type='text'
              name='nombre'
              id='nombre'
              placeholder='Ingrese su nombre completo'
              value={formik.values.nombre}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.nombre && formik.errors.nombre ? (
              <Alerta>{formik.errors.nombre}</Alerta>
            ) : null}

            <Label htmlFor='email'>Email</Label>
            <Input
              type='email'
              name='email'
              id='email'
              placeholder='Ingrese su correo electrónico'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <Alerta>{formik.errors.email}</Alerta>
            ) : null}

            <Label htmlFor='tel'>Teléfono</Label>
            <Input
              type='tel'
              name='tel'
              id='tel'
              placeholder='Ingrese su telefono'
              value={formik.values.tel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.tel && formik.errors.tel ? (
              <Alerta>{formik.errors.tel}</Alerta>
            ) : null}

            <Label htmlFor='mensaje'>Mensaje</Label>
            <TextArea
              type='text'
              name='mensaje'
              id='mensaje'
              placeholder='Añadir un Mensaje...'
              value={formik.values.mensaje}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ width: 'auto' }}
            />
            {formik.touched.mensaje && formik.errors.mensaje ? (
              <Alerta>{formik.errors.mensaje}</Alerta>
            ) : null}
          </Box>

          {/* <Button type="submit" value="ENVIAR MENSAJE" /> */}
          <Button type='submit'>ENVIAR MENSAJE</Button>
        </FormContainer>
        <InfoContainer>
          {/* <Title>Contacto</Title> */}
          <Desc>
            <ContactItem>
              <Room style={{ marginRight: '10px' }} />{' '}
              {info?.address || 'Ubicacion de la tienda'}
            </ContactItem>
            <ContactItem>
              <Phone style={{ marginRight: '10px' }} />
              <a
                href={`tel:+${info?.tel}`}
                rel='noopener noreferrer'
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                Llamar ahora
              </a>
            </ContactItem>
            <ContactItem>
              <WhatsApp style={{ marginRight: '10px' }} /> WhatsApp:
              <a
                href={`https://api.whatsapp.com/send?phone=${info?.phone}`}
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  marginLeft: '5px',
                  color: 'blue',
                  textDecoration: 'underline',
                }}
              >
                {info?.phone || 'Teléfono'}
              </a>
            </ContactItem>
            <ContactItem>
              <MailOutline style={{ marginRight: '10px' }} />
              <a
                href={`mailto:${info?.email}`}
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                {info?.email || 'Email'}
              </a>
            </ContactItem>
          </Desc>
          <Iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.5977690289337!2d-65.2167216!3d-26.8209333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225c40f8294b3d%3A0x8bf216bc49610be4!2sAv.%20Mitre%20520%2C%20T4000%20San%20Miguel%20de%20Tucum%C3%A1n%2C%20Tucum%C3%A1n!5e0!3m2!1ses-419!2sar!4v1657148763153!5m2!1ses-419!2sar'
            allowFullScreen=''
            loadind='lazy'
          />
        </InfoContainer>
      </Wrapper>
      <Footer />
    </Layout>
  );
};

export default Contacto;
