import {
  loginFailure,
  loginStart,
  loginSuccess,
} from '../features/user/userSlice';
import { publicRequest, userRequest } from '../config/clienteAxios';
import {
  addProductFailure,
  addProductStart,
  addProductSuccess,
  getProductFailure,
  getProductStart,
  getProductSuccess,
  updateProductFailure,
  updateProductStart,
  updateProductSuccess,
} from '../features/product/productSlice';
import {
  getCategoryFailure,
  getCategoryStart,
  getCategorySuccess,
} from '../features/category/categorySlice';
import {
  getBannerFailure,
  getBannerStart,
  getBannerSuccess,
} from '../features/banner/bannerSlice';
import {
  getInfoFailure,
  getInfoNosotrosFailure,
  getInfoNosotrosStart,
  getInfoNosotrosSuccess,
  getInfoStart,
  getInfoSuccess,
} from '../features/info/infoSlice';

const accessToken = JSON.parse(localStorage.getItem('token_user'));

// ------------------ LOGIN ------------------
export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post('/auth/login', user);
    // const { _id, name, email, isAdmin, accessToken } = res.data;
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure(err.response.data.msg));
  }
};

// ------------------ ADMIN ------------------

// ------------------ PRODUCTOS ------------------
export const getProducts = async (dispatch) => {
  dispatch(getProductStart());

  try {
    const res = await publicRequest.get('/productos');
    dispatch(getProductSuccess(res.data));
  } catch (err) {
    console.log('Soy error', err);
    dispatch(getProductFailure());
  }
};

export const addProduct = async (product, dispatch) => {
  dispatch(addProductStart());
  try {
    const res = await userRequest.post(`/productos`, product, {
      headers: { token: accessToken },
    });
    dispatch(addProductSuccess(res.data));
  } catch (err) {
    dispatch(addProductFailure());
  }
};

export const updateProduct = async (id, product, dispatch) => {
  dispatch(updateProductStart());
  try {
    const res = await userRequest.put(`/productos/${id}`, product, {
      headers: { token: accessToken },
    });
    // dispatch(updateProductSuccess({ id, product }));
    dispatch(updateProductSuccess(res.data));
  } catch (err) {
    dispatch(updateProductFailure());
  }
};

// export const deleteProduct = async (id, dispatch) => {
//   dispatch(deleteProductStart());
//   try {
//     await userRequest.delete(`/productos/${id}`, {
//       headers: { token: accessToken },
//     });
//     // dispatch(deleteProductSuccess(res.data));
//     dispatch(deleteProductSuccess(id));
//   } catch (err) {
//     dispatch(deleteProductFailure());
//   }
// };

// ------------------ CATEGORIAS ------------------
export const getCategories = async (dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await publicRequest.get('/categorias');
    dispatch(getCategorySuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailure());
  }
};

// ------------------ BANNER ------------------
export const getBanner = async (dispatch) => {
  dispatch(getBannerStart());
  try {
    const res = await publicRequest.get('/banner');
    dispatch(getBannerSuccess(res.data));
  } catch (err) {
    dispatch(getBannerFailure());
  }
};

// ------------------ INFO ------------------
export const getInfo = async (dispatch) => {
  dispatch(getInfoStart());
  try {
    const res = await publicRequest.get('/infotienda');
    dispatch(getInfoSuccess(res.data[0]));
  } catch (err) {
    dispatch(getInfoFailure());
  }
};

// ------------------ NOSOTROS ------------------
export const getInfoNosotros = async (dispatch) => {
  dispatch(getInfoNosotrosStart());
  try {
    const res = await publicRequest.get('/infotienda/nosotros');
    dispatch(getInfoNosotrosSuccess(res.data.data));
  } catch (err) {
    dispatch(getInfoNosotrosFailure());
  }
};

// ------------------ COLORES WEB ------------------
export const getInfoColors = async () => {
  const { data } = await publicRequest.get('/infotienda');
  return data[0].colors[0];
};
