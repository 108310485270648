import { useState } from 'react';
import { publicRequest } from 'config/clienteAxios';
import {
  ArrowBack,
  CheckCircleOutline,
  ErrorOutline,
  LockOutlined,
} from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import {
  Alerta,
  BoxInput,
  Button,
  Container,
  Error,
  Form,
  Icon,
  Input,
  Links,
  Success,
  Title,
  Wrapper,
} from './ResetPasswordStyles';

const ResetPassword = () => {
  const location = useLocation();
  const token = location.pathname.split('/')[2];
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState('');

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Campo requerido'),
      confirmPassword: Yup.string()
        .required('Campo requerido')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),
    }),
    onSubmit: (valores, { resetForm }) => {
      resetPassword(valores);
      resetForm({ valores: '' }); // Limpiar el formulario
    },
  });

  const resetPassword = (inputs) => {
    const resetPass = async () => {
      const res = await publicRequest.post(
        `/auth/resetPassword/${token}`,
        inputs
      );
      setSuccess(res.data.success);
      setError(res.data.success);
      setMsg(res.data.msg);
    };
    resetPass();

    setTimeout(() => {
      setSuccess(false);
      setError(true);
    }, 4000);
  };

  return (
    <Container>
      <Wrapper>
        <Links to='/iniciar-sesion' className='link'>
          <ArrowBack />
        </Links>
        <Title>Restablecer contraseña</Title>

        {success && (
          <Success>
            {' '}
            <CheckCircleOutline style={{ marginRight: '10px' }} /> {msg}
          </Success>
        )}
        {!error && (
          <Error>
            {' '}
            <ErrorOutline style={{ marginRight: '10px' }} /> {msg}
          </Error>
        )}

        <Form onSubmit={formik.handleSubmit}>
          <BoxInput>
            <Icon>
              <LockOutlined />
            </Icon>
            <Input
              type='password'
              id='password'
              placeholder='Nueva Contraseña'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.password && formik.errors.password ? (
            <Alerta>{formik.errors.password}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <LockOutlined />
            </Icon>
            <Input
              type='password'
              id='confirmPassword'
              placeholder='Confirmar Contraseña'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <Alerta>{formik.errors.confirmPassword}</Alerta>
          ) : null}

          <Button type='submit'>Cambiar contraseña</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default ResetPassword;
