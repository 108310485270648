import React from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import LogoTienda from 'assets/images/logo-tienda.png';
import {
  Title,
  TitleContainer,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';
import Spinner from 'components/spinner/Spinner';

import styles from './orderView.module.css';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { userRequest } from 'config/clienteAxios';
import { currencyFormat } from 'utils/data-conversion';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OrderPDF from './OrderPDF';

const UserView = () => {
  const location = useLocation();
  const orderId = location.pathname.split('/')[2];
  const [order, setOrder] = useState({});
  const [productsOrder, setProductsOrder] = useState([]);
  let productsAll = [];

  useEffect(() => {
    const getOrderId = async () => {
      const accessToken = JSON.parse(localStorage.getItem('token_user'));
      const res = await userRequest.get(`/ordenes/${orderId}`, {
        headers: { token: accessToken },
      });

      setOrder(res.data);
    };
    getOrderId();
  }, [orderId]);

  const {
    // _id,
    orderNum,
    date,
    hour,
    subtotal,
    shipment,
    additional,
    total,
    discountCoupon,
    shipmentForm,
    paymentForm,
    // status,
    userId,
    products,
    userInvite,
  } = order;

  const getProductsData = async () => {
    const accessToken = JSON.parse(localStorage.getItem('token_user'));
    const res = await userRequest.get(`productos`, {
      headers: { token: accessToken },
    });
    productsAll = res.data.products;
    //reemplazar por un map.
    const arrayAux = products?.map((product) => {
      let findProduct = productsAll?.find(
        (prod) => prod.id == product.productId
      );
      if (findProduct) {
        const { id, price, title, details } = findProduct;
        let newProduct = { id, price, title, details, ...product };
        return newProduct;
      }
    });
    setProductsOrder(arrayAux);
  };

  const handleMostarDesc = (total) => {
    if (discountCoupon) {
      if (discountCoupon.tipo === 'porcentaje') {
        const des = (total * discountCoupon.descuento) / 100;
        const res = des.toFixed(2);
        return res;
      }
      if (discountCoupon.tipo === 'importe') {
        const res = discountCoupon.descuento;
        return res;
      }
    }
    return total;
  };

  useEffect(() => {
    getProductsData();
  }, [products]);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <TitleContainer>
            <Title>Detalles de Orden</Title>
            <PDFDownloadLink
              document={<OrderPDF order={order} />}
              fileName='Detalle_Orden'
            >
              <button className={styles.imprimirPDF}>Descargar PDF</button>
            </PDFDownloadLink>
          </TitleContainer>

          <div className={styles.userContainer}>
            {Object.keys(order).length > 0 ? (
              <div className={styles.userShow}>
                <div className={styles.userShowTop}>
                  <img
                    src={LogoTienda}
                    alt='Logo Aire De Lola'
                    width={'40px'}
                    style={{ marginLeft: '20px ' }}
                  />
                </div>
                <div className={styles.userShowBottom}>
                  <div className={styles.main__orden}>
                    <div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>(Nombre del reponsable)</strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>(DNI)</strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>(Direccion)</strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>Teléfono:</strong> (Telefono)
                        </span>
                      </div>
                      <div
                        className={styles.userShowInfo}
                        style={{ marginTop: '30px' }}
                      >
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>N° Pedido:</strong> {orderNum}
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>date:</strong> {date}
                          <strong>hour:</strong> {hour}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>Nombre y apellidos:</strong>{' '}
                          {userId ? order.User?.name : userInvite?.name}{' '}
                          {userId ? order.User?.lastName : userInvite?.lastName}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>Dirección:</strong>{' '}
                          {userId ? order.User?.address : userInvite?.address}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>
                            {userId
                              ? order.User?.codPostal
                              : userInvite?.codPostal}
                          </strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>
                            {userId ? order.User?.city : userInvite?.city} -{' '}
                            {userId ? order.User?.prov : userInvite?.prov}
                          </strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>DNI:</strong>{' '}
                          {userId ? order.User?.dni : userInvite?.dni}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>Teléfono:</strong>{' '}
                          {userId ? order.User?.tel : userInvite?.tel}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {' '}
                          <strong>
                            {userId ? order.User?.email : userInvite?.email}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* TABLA DE PRODUCTOS */}
                  <div
                    className={styles.userShowInfo}
                    style={{ marginTop: '30px' }}
                  >
                    <span className={styles.userShowInfoTitle}>
                      {' '}
                      <strong>Productos</strong>{' '}
                    </span>
                  </div>
                  <div className={styles.userShowInfoProduct}>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th className={styles.th}>Código</th>
                          <th className={styles.th}>Descripcion</th>
                          <th className={styles.th}>Medidas</th>
                          <th className={styles.th}>Color</th>
                          <th className={styles.th}>Cant.</th>
                          <th className={styles.th}>Precio</th>
                          <th className={styles.th}>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productsOrder?.map((product, index) => (
                          <tr key={index}>
                            <td className={styles.td}>{product.cod}</td>
                            <td className={styles.td}>{product.title}</td>
                            <td className={styles.td}>
                              {product.details.map((detail) => detail.measures)}
                            </td>
                            <td className={styles.td}>
                              {product.details.map((detail) => detail.color)}
                            </td>
                            <td className={styles.td}>{product.quantity}</td>
                            <td className={styles.td}>
                              {currencyFormat(product.price)}
                            </td>
                            <td className={styles.td}>
                              {currencyFormat(product.price * product.quantity)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.userShowInfoUser}>
                    <span className={styles.userShowInfoTitle}>
                      {' '}
                      <strong>Forma de Pago:</strong>{' '}
                      {paymentForm?.toUpperCase()}
                      {shipmentForm === 'retiradaentienda' ? (
                        <strong>Retirada en tienda</strong>
                      ) : (
                        <>
                          <strong>shipment:</strong> {currencyFormat(shipment)}
                        </>
                      )}
                      {paymentForm === 'contrareembolso' && (
                        <>
                          <strong>Adic. Contrareembolso:</strong>{' '}
                          {currencyFormat(additional)}
                        </>
                      )}
                      {discountCoupon === 0 ? null : (
                        <>
                          <strong>Cupón descuento:</strong> -
                          {currencyFormat(handleMostarDesc(subtotal))}{' '}
                        </>
                      )}
                      <strong>Total:</strong> {currencyFormat(total)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default UserView;
