import React, { useEffect } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  Image,
  Span,
  Table,
  TableContainer,
  Title,
  TitleContainer,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from 'api/apiCalls';
import { DataGridTable } from 'utils/data-grid-table';
import { Edit } from '@material-ui/icons';
import { currencyFormat } from 'utils/data-conversion';

const Ofertas = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);

  const productFilter = products.products.filter(
    (product) => product.discount > 0
  );

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);

  const columns = [
    // { field: "_id", headerName: "ID", width: 100 },
    {
      field: 'cod',
      headerName: 'Código',
      width: 120,
    },
    {
      field: 'product',
      headerName: 'Producto',
      width: 170,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image src={params.row.img?.[0]} alt='' />
            {params.row.title}
          </div>
        );
      },
    },

    {
      field: 'price',
      headerName: 'Precio',
      width: 140,
      renderCell: (params) => {
        return <div>{currencyFormat(params.row.price)}</div>;
      },
    },
    {
      field: 'categories',
      headerName: 'Categorias',
      width: 160,
      renderCell: (params) => {
        return <div>{params.row.categories}</div>;
      },
    },
    {
      field: 'details.stock',
      headerName: 'Stock',
      width: 116,
      renderCell: (params) => {
        return (
          <div>
            {params.row.details
              .map((item) => Number(item.stock))
              .reduce((prev, curr) => prev + curr, 0)}
          </div>
        );
      },
    },
    {
      field: 'discount',
      headerName: 'Descuento',
      width: 160,
      renderCell: (params) => {
        return <div>{params.row.discount + '%'}</div>;
      },
    },
    {
      field: 'action',
      headerName: 'Acciones',
      width: 145,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/editProducto/' + params.row.id}>
              <Button
                bgColor='alternative'
                padding='5px 7px'
                margin='0 10px 0 0'
                color='#fff'
              >
                <Edit />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <TitleContainer>
            <Title>
              Productos en Descuento{' '}
              <Span>({productFilter.length} productos)</Span>
            </Title>
          </TitleContainer>

          <TableContainer>
            <Table>
              <DataGridTable data={productFilter} columns={columns} />
            </Table>
          </TableContainer>
        </Wrapper>
      </div>
    </>
  );
};

export default Ofertas;
