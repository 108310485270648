import React, { useEffect, useState } from 'react';
import Footer from 'components/footer/Footer';
import Product from 'components/product/Product';
import Spinner from 'components/spinner/Spinner';

import { publicRequest } from 'config/clienteAxios';
import { FilterListOutlined, Search } from '@material-ui/icons';
import Slider from '@material-ui/core/Slider';
import { Paginate } from 'utils/react-pagination';
import { currencyFormat } from 'utils/data-conversion';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import Layout from 'components/layouts/Layout';
import {
  Box,
  Box1,
  Box2,
  BtnFilter,
  Container2,
  Filter,
  FilterBtnContainer,
  FilterContainer,
  FilterText,
  Input,
  Option,
  SearchBox,
  SearchContainer,
  Select,
  Title,
  Wrapper,
} from './OfertasProductsStyles';

const OfertasProducts = () => {
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  // Filtros
  const [filters, setFilters] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  // const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState('initial');
  const [showFilter, setShowFilter] = useState(false);

  // Slider Rangos de Precios
  const [rangePrice, setRangePrice] = useState([0, 50000]);
  const handleChange = (event, newValue) => {
    setRangePrice(newValue);
  };

  // Paginacion
  const usersPerPage = 12;
  const pagesVisited = pageNumber * usersPerPage;

  const pageCount = Math.ceil(products.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Categorias
  const categories = useSelector((state) => state.category.categories);
  const b = products.map((item) => {
    const res = item.details.map((i) => i.brand);
    return res;
  });
  const dataBrand = b.flat();
  const brands = [...new Set(dataBrand)];

  useEffect(() => {
    const getProducts = async () => {
      try {
        const { data } = await publicRequest.get('/productos');
        const resProducts = data.products.filter((p) => p.discount > 0);
        setProducts(resProducts);
      } catch (error) {}
    };
    getProducts();
  }, []);

  const handleFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setFilteredProducts(
      products.filter((item) =>
        Object.entries(filters).every(([key, value]) =>
          item[key].includes(value)
        )
      )
    );

  }, [products, filters]);

  useEffect(() => {
    if (sort === 'nuevo') {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === 'asc') {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);

  // Para el filtro de tallas
  useEffect(() => {
    if (filters?.categories && brand) {
      setFilteredProducts(
        products.filter((item) => {
          return (
            item.categories === filters?.categories &&
            item.details.some((i) => i.brand === brand)
          );
        })
      );
    } else if (filters?.categories && brand === '') {
      setFilteredProducts(
        products.filter((item) => {
          return item.categories === filters?.categories;
        })
      );
    } else if (brand === '') {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((item) => {
          return item.details.some((i) => i.brand === brand);
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  // Para la barra de busqueda
  useEffect(() => {
    if (search === '') {
      setFilteredProducts(products);
    }
    setFilteredProducts((prev) =>
      [...prev].filter((product) =>
        product.title.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [products, search]);

  useEffect(() => {
    if (rangePrice.length === 0) {
      setFilteredProducts(products);
    } else if (filters?.categories && brand && rangePrice) {
      setFilteredProducts(
        products.filter((item) => {
          return (
            item.categories === filters?.categories &&
            item.details.some((i) => i.brand === brand) &&
            item.price >= rangePrice[0] &&
            item.price <= rangePrice[1]
          );
        })
      );
    } else {
      setFilteredProducts(
        products.filter((item) => {
          return item.price >= rangePrice[0] && item.price <= rangePrice[1];
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangePrice, products]);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
    <Layout title='Ofertas'>
      <Box>
        <Title>Ofertas</Title>
      </Box>

      <SearchContainer>
        <SearchBox>
          <Search style={{ color: 'gray' }} />
          <Input
            type='text'
            placeholder='Busqueda'
            onChange={(e) => setSearch(e.target.value)}
          />
        </SearchBox>
      </SearchContainer>

      <FilterBtnContainer>
        <BtnFilter type='button' onClick={handleShowFilter}>
          <FilterListOutlined style={{ marginRight: '5px' }} /> Filtros
        </BtnFilter>
      </FilterBtnContainer>

      {/* Filtros version mobile */}
      {showFilter ? (
        <section data-aos='zoom-in'>
          <FilterContainer>
            <Filter>
              <FilterText>Categorias:</FilterText>
              <Select
                name='categories'
                onChange={handleFilters}
                style={{ width: '140px' }}
              >
                <Option value=''>Todas</Option>
                {categories.map((c) => (
                  <Option key={c.name} value={c.name}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Filter>
            <Filter>
              <FilterText>Ordenar por:</FilterText>
              <Select
                onChange={(e) => setSort(e.target.value)}
                style={{ width: '140px' }}
              >
                <Option value='nuevo'>Lo mas nuevo</Option>
                <Option value='asc'>Precio más bajo</Option>
                <Option value='desc'>Precio más alto</Option>
              </Select>
            </Filter>
          </FilterContainer>
          <FilterContainer>
            <Filter>
              <FilterText>Marcas:</FilterText>
              <Select name='brand' onChange={(e) => setBrand(e.target.value)}>
                <Option value=''>Todas</Option>
                <Option value='BMW'>BMW</Option>
                <Option value='Ferrari'>Ferrari</Option>
                <Option value='Mercedes Benz'>Mercedes Benz</Option>
              </Select>
            </Filter>
          </FilterContainer>
          <FilterContainer>
            <Filter>
              <FilterText>Precios:</FilterText>
              <Slider
                style={{
                  width: '300px',
                  margin: '0 20px 0 0',
                  color: '#0465eb',
                }}
                step={2000}
                min={0}
                max={50000}
                value={rangePrice}
                onChange={handleChange}
                valueLabelDisplay='auto'
                aria-labelledby='range-slider'
              />
              <div
                style={{
                  width: '100%',
                  height: '25px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span style={{ textAlign: 'left' }}>
                  {currencyFormat(rangePrice[0])}
                </span>
                <span style={{ textAlign: 'right' }}>
                  {currencyFormat(rangePrice[1])}
                </span>
              </div>
            </Filter>
          </FilterContainer>
        </section>
      ) : null}

      <Container2>
        <Box1>
          <div>
            <h3>Categorias</h3>
            <div>
              {categories.length > 0 ? (
                <>
                  <p style={{ color: '#000' }}>
                    <input
                      name='categories'
                      id='cat'
                      type='checkbox'
                      checked={filters?.categories === ''}
                      value=''
                      onChange={handleFilters}
                    />{' '}
                    <label htmlFor='cat'>
                      Todas{' '}
                      <span
                        style={{
                          fontSize: '12px',
                          color: '#0465eb',
                          fontWeight: 'bold',
                        }}
                      >
                        ({products.length})
                      </span>
                    </label>
                  </p>
                  {categories.map((c, index) => (
                    <p key={index} style={{ color: '#000' }}>
                      <input
                        name='categories'
                        id={c.name}
                        type='checkbox'
                        checked={filters?.categories === c.name}
                        value={c.name}
                        onChange={handleFilters}
                      />{' '}
                      <label htmlFor={c.name}>{c.name}</label>
                    </p>
                  ))}
                </>
              ) : (
                <p>Cargando...</p>
              )}
            </div>
          </div>
          <div>
            <h3>Marcas</h3>
            <div>
              {categories.length > 0 ? (
                <>
                  <p style={{ color: '#000' }}>
                    <input
                      name='brand'
                      id='brand'
                      type='checkbox'
                      value=''
                      checked={brand === ''}
                      onChange={(e) => setBrand(e.target.value)}
                    />{' '}
                    <label htmlFor='brand'>Todas</label>
                  </p>
                  {brands.map((item, index) => (
                    <p key={index} style={{ color: '#000' }}>
                      <input
                        name='brand'
                        id={item}
                        type='checkbox'
                        value={item}
                        checked={brand === item}
                        onChange={(e) => setBrand(e.target.value)}
                      />{' '}
                      <label htmlFor={item}>{item}</label>
                    </p>
                  ))}
                </>
              ) : (
                <p>Cargando...</p>
              )}
            </div>
          </div>
          <div>
            <h3>Rango Precios</h3>
            <div style={{ height: '100px', overflow: 'hidden' }}>
              {categories.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Slider
                    style={{
                      width: '180px',
                      margin: '40px 20px 0',
                      color: '#0465eb',
                    }}
                    step={2000}
                    min={0}
                    max={50000}
                    value={rangePrice}
                    onChange={handleChange}
                    valueLabelDisplay='auto'
                    aria-labelledby='range-slider'
                  />
                  <div
                    style={{
                      width: '100%',
                      height: '25px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span style={{ textAlign: 'left' }}>
                      {currencyFormat(rangePrice[0])}
                    </span>
                    <span style={{ textAlign: 'right' }}>
                      {currencyFormat(rangePrice[1])}
                    </span>
                  </div>
                </div>
              ) : (
                <p>Cargando...</p>
              )}
            </div>
          </div>
          <div>
            <h3>Ordenar por</h3>
            <div style={{ height: '50px' }}>
              {categories.length > 0 ? (
                <>
                  <p style={{ color: '#000' }}>
                    <input
                      id='ord2'
                      checked={sort === 'asc'}
                      type='checkbox'
                      value='asc'
                      onChange={(e) => setSort(e.target.value)}
                    />{' '}
                    <label htmlFor='ord2'>Precio más bajo</label>
                  </p>
                  <p style={{ color: '#000' }}>
                    <input
                      id='ord3'
                      checked={sort === 'desc'}
                      type='checkbox'
                      value='desc'
                      onChange={(e) => setSort(e.target.value)}
                    />{' '}
                    <label htmlFor='ord3'>Precio más alto</label>
                  </p>
                </>
              ) : (
                <p>Cargando...</p>
              )}
            </div>
          </div>
        </Box1>

        <Box2>
          {products.length > 0 ? (
            <>
              <Wrapper>
                {/* Si hay filtros seleccionados que pase y verifique que hay al menos 1 filtro */}
                {filteredProducts
                  ? filteredProducts
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((item) => <Product item={item} key={item.id} />)
                  : products
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((item) => {
                        return <Product item={item} key={item.id} />;
                      })}
                {filteredProducts.length === 0 && (
                  <p style={{ margin: '200px auto 250px' }}>
                    No se encontraron resultados...
                  </p>
                )}
              </Wrapper>
              {filteredProducts.length > 12 ? (
                <Paginate pageCount={pageCount} changePage={changePage} />
              ) : null}
            </>
          ) : (
            <Spinner />
          )}
        </Box2>
      </Container2>

      <Footer />
    </Layout>
  );
};

export default OfertasProducts;
