import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Select,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { userRequest } from 'config/clienteAxios';
import { useSelector } from 'react-redux';

const User = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const userId = location.pathname.split('/')[2];
  const history = useHistory();
  const accessToken = JSON.parse(localStorage.getItem('token_user'));
  const [user, setUser] = useState({
    name: '',
    lastName: '',
    dni: '',
    email: '',
    address: '',
    tel: '',
    poblac: '',
    prov: '',
    postalCod: '',
    isAdmin: '',
  });

  const handleInputChange = (e) => {
    setUser((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await userRequest.put(`/usuarios/${userId}`, user, {
        headers: { token: accessToken },
      });
      alertSuccess('¡Usuario actualizado!');
      history.push('/usuarios');
    } catch (err) {}
  };

  const getUserId = async () => {
    try {
      const res = await userRequest.get(`/usuarios/find/${userId}`, {
        headers: { token: accessToken },
      });
      setUser(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Editar Usuario</Title>
          <Form>
            <BoxInput>
              <Label>Nombre</Label>
              <Input
                name='name'
                type='text'
                value={user?.name || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Apellidos</Label>
              <Input
                name='lastName'
                type='text'
                value={user?.lastName || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>DNI</Label>
              <Input
                name='dni'
                type='text'
                value={user?.dni || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Email</Label>
              <Input
                name='email'
                type='text'
                value={user?.email || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Dirección</Label>
              <Input
                name='address'
                type='text'
                value={user?.address || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Teléfono</Label>
              <Input
                name='tel'
                type='text'
                value={user?.tel || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Ciudad</Label>
              <Input
                name='poblac'
                type='text'
                value={user?.poblac || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Provincia</Label>
              <Input
                name='prov'
                type='text'
                value={user?.prov || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Código Postal</Label>
              <Input
                name='postalCod'
                type='text'
                value={user?.postalCod || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Admin</Label>
              <Select
                name='isAdmin'
                id='isAdmin'
                value={user.isAdmin}
                onChange={handleInputChange}
                disabled={currentUser?.id === user?.id ? true : false}
              >
                <option value='true'>Si</option>
                <option value='false'>No</option>
              </Select>
            </BoxInput>

            <BoxButtons>
              <Button
                color='#fff'
                padding='10px 15px'
                margin='10px 10px 0 0'
                onClick={handleFormSubmit}
              >
                Actualizar
              </Button>
              <Link to='/usuarios' className='link'>
                <Button
                  bgColor='cancel'
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default User;
