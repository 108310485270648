import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ScrollToTop from 'components/scrollToTop/ScrollToTop';
import { useSelector } from 'react-redux';

import {
  // Home,
  Login,
  Register,
  Product,
  ProductList,
  ProductsAll,
  Cart,
  Nosotros,
  Contacto,
  NotFound,
  Fav,
  ConfirmEmail,
  ForgotPassword,
  ResetPassword,
  OfertasProducts,
  Success,
  SuccessCard,
  UserInvite,
  SuccessUserInvite,
  Error,
  Profile,
  ProfileEdit,
  Orders,
  ViewOrder,
  Envio,
  Devoluciones,
  BotonArrepentimiento,
} from '../pages/web';

import {
  Administrador,
  Dashboard,
  UserList,
  NewUser,
  User,
  UserView,
  ProductListA,
  NewProduct,
  ProductA,
  OrderList,
  OrderEdit,
  OrderView,
  CategoryList,
  NewCategory,
  CategoryEdit,
  Cupones,
  Banner,
  Ofertas,
  InfoNosotros,
  InfoNosotrosEdit,
  Configuracion,
  NewConfiguracion,
  Costos,
  Estilos,
} from '../pages/administrador';
import { lazy, Suspense } from 'react';

const HomePage = lazy(() => import('pages/web/home/Home'));

function App() {
  const user = useSelector((state) => state.user.currentUser);
  const admin = useSelector((state) => state.user.currentUser?.isAdmin);

  const products = useSelector((state) => state.product.products);
  const resProducts = products?.products?.filter((p) => p.discount > 0) || [];

  return (
    <div>
      <Router>
        <Suspense
          fallback={
            <div style={{ textAlign: 'center', fontSize: '50px' }}>
              Loading...
            </div>
          }
        >
          <ScrollToTop>
            <Switch>
              <Route exact path='/'>
                <HomePage />
              </Route>
              <Route path='/iniciar-sesion'>
                {user ? <Redirect to='/' /> : <Login />}
              </Route>
              <Route path='/registrarse'>
                {user ? <Redirect to='/' /> : <Register />}
              </Route>

              <Route path='/lista-productos'>
                <ProductsAll />
              </Route>
              <Route path='/productos/:slug'>
                <ProductList />
              </Route>
              <Route path='/producto/:id'>
                <Product />
              </Route>
              <Route path='/carrito'>
                <Cart />
              </Route>
              <Route path='/favoritos'>
                <Fav />
              </Route>
              <Route path='/nosotros'>
                <Nosotros />
              </Route>
              <Route path='/contacto'>
                <Contacto />
              </Route>
              <Route path='/confirmacion-cuenta/:token'>
                <ConfirmEmail />
              </Route>
              <Route path='/forgot-password'>
                <ForgotPassword />
              </Route>
              <Route path='/reset-password/:token'>
                <ResetPassword />
              </Route>
              <Route path='/envio'>
                <Envio />
              </Route>
              <Route path='/devoluciones'>
                <Devoluciones />
              </Route>
              <Route path='/boton-arrepentimiento'>
                <BotonArrepentimiento />
              </Route>
              <Route path='/compra'>
                <UserInvite />
              </Route>
              <Route path='/success-compra'>
                <SuccessUserInvite />
              </Route>
              {resProducts.length > 0 && (
                <Route path='/ofertas'>
                  <OfertasProducts />
                </Route>
              )}

              {user && (
                <Switch>
                  <Route path='/perfil'>
                    <Profile />
                  </Route>
                  <Route path='/perfil-edit'>
                    <ProfileEdit />
                  </Route>
                  <Route path='/pedidos'>
                    <Orders />
                  </Route>
                  <Route path='/viewPedido/:userId'>
                    <ViewOrder />
                  </Route>
                  <Route path='/success'>
                    <Success />
                  </Route>
                  <Route path='/success-card'>
                    <SuccessCard />
                  </Route>
                  <Route path='/error'>
                    <Error />
                  </Route>

                  {/* RUTAS ADMIN */}
                  {admin && (
                    <Switch>
                      <Route exact path='/administrador'>
                        <Administrador />
                      </Route>
                      <Route exact path='/inicio'>
                        <Dashboard />
                      </Route>

                      <Route path='/usuarios'>
                        <UserList />
                      </Route>
                      <Route path='/editUsuario/:userId'>
                        <User />
                      </Route>
                      <Route path='/viewUsuario/:userId'>
                        <UserView />
                      </Route>
                      <Route path='/nuevoUsuario'>
                        <NewUser />
                      </Route>

                      <Route path='/productos'>
                        <ProductListA />
                      </Route>
                      <Route path='/editProducto/:productoId'>
                        <ProductA />
                      </Route>
                      <Route path='/nuevoProducto'>
                        <NewProduct />
                      </Route>

                      <Route path='/ordenes'>
                        <OrderList />
                      </Route>
                      <Route path='/viewOrden/:ordenId'>
                        <OrderView />
                      </Route>
                      <Route path='/editOrden/:ordenId'>
                        <OrderEdit />
                      </Route>

                      <Route path='/categorias'>
                        <CategoryList />
                      </Route>
                      <Route path='/nuevaCategoria'>
                        <NewCategory />
                      </Route>
                      <Route path='/editCategoria/:catId'>
                        <CategoryEdit />
                      </Route>

                      <Route path='/configuracion'>
                        <Configuracion />
                      </Route>
                      <Route path='/nuevaConfiguracion'>
                        <NewConfiguracion />
                      </Route>
                      <Route path='/costos'>
                        <Costos />
                      </Route>
                      <Route path='/estilos'>
                        <Estilos />
                      </Route>
                      <Route path='/banner'>
                        <Banner />
                      </Route>
                      <Route path='/cupones'>
                        <Cupones />
                      </Route>
                      <Route path='/info-nosotros'>
                        <InfoNosotros />
                      </Route>
                      <Route path='/edit/info-nosotros/:id'>
                        <InfoNosotrosEdit />
                      </Route>
                      <Route path='/ofertas-productos'>
                        <Ofertas />
                      </Route>
                      <Route path='/*'>
                        <NotFound />
                      </Route>
                    </Switch>
                  )}
                  <Route path='/*'>
                    <NotFound />
                  </Route>
                </Switch>
              )}

              <Route path='/*'>
                <NotFound />
              </Route>
            </Switch>
          </ScrollToTop>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
