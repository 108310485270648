import Footer from 'components/footer/Footer';
import { Link } from 'react-router-dom';
import { Clear } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProductFav } from 'features/fav/favSlice';
import { currencyFormat } from 'utils/data-conversion';
import Layout from 'components/layouts/Layout';
import {
  Bottom,
  Box,
  ButtonDelete,
  Details,
  Hr,
  Image,
  Info,
  Links,
  PriceDetail,
  Product,
  ProductDetail,
  ProductId,
  ProductName,
  ProductPrice,
  Title,
  Top,
  TopButton,
  TopText,
  TopTexts,
  Wrapper,
} from './FavStyles';
import { API_URL_IMG } from 'utils/constants';

const Fav = () => {
  const fav = useSelector((state) => state.fav);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(deleteProductFav({ id }));
  };

  return (
    <Layout title='Favoritos'>
      <Wrapper>
        <Title>Favoritos</Title>
        <Top>
          <TopButton>
            <Links to='/lista-productos'>Seguir agregando a favoritos</Links>
          </TopButton>
          <TopTexts>
            <TopText>
              Productos en favoritos (<b>{fav.quantity}</b>)
            </TopText>
          </TopTexts>
        </Top>
        <Bottom>
          <Info>
            {fav.products.length >= 1 ? (
              fav.products.map((product, index) => (
                <div key={index}>
                  <Product>
                    <ProductDetail>
                      <Link to={`/producto/${product.slug}`}>
                        <Image src={API_URL_IMG + product.img?.[0]} />
                      </Link>

                      <Details>
                        <ProductName>
                          <b>{product.title}</b>
                        </ProductName>
                        <ProductId>
                          <b>Cod.</b> {product.cod}
                        </ProductId>
                      </Details>
                    </ProductDetail>
                    <Box>
                      <PriceDetail>
                        <ProductPrice>
                          {currencyFormat(product.price)}
                        </ProductPrice>
                      </PriceDetail>
                      <ButtonDelete onClick={() => handleDelete(product._id)}>
                        <Clear />
                      </ButtonDelete>
                    </Box>
                  </Product>
                  <Hr />
                </div>
              ))
            ) : (
              <p style={{ textAlign: 'center', margin: '200px 0' }}>
                No hay productos en favoritos
              </p>
            )}
          </Info>
        </Bottom>
      </Wrapper>
      <Footer />
    </Layout>
  );
};

export default Fav;
