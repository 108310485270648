import { useState, useEffect } from 'react';

import styled from 'styled-components';
import { publicRequest } from 'config/clienteAxios';
import Product from './Product';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Products = ({ cat, filters, sort }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        // category: en el controlador puse como query ese nombre
        const res = await publicRequest.get(
          cat ? `/productos?category=${cat}` : '/productos'
        );
        setProducts(res.data.products);
      } catch (error) {}
    };
    getProducts();
  }, [cat]);

  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    setFilteredProducts(
      products.filter((item) => {
        return item.details.some((i) => i.brand === filters?.brand);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]); // Funciona

  useEffect(() => {
    if (sort === 'nuevo') {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === 'asc') {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);

  return (
    <Container>
      {cat
        ? filteredProducts.map((item) => <Product item={item} key={item.id} />)
        : products
            .slice(0, 12)
            .filter((item) => item.status !== false)
            .map((item) => <Product item={item} key={item.id} />)}
    </Container>
  );
};

export default Products;
