import React, { useEffect } from 'react';
import Footer from 'components/footer/Footer';

import styled from 'styled-components';
import { mobile, mobileLg, mobileMd } from 'styles/responsive';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoNosotros } from 'api/apiCalls';
import Layout from 'components/layouts/Layout';

const Wrapper = styled.div`
  margin-top: 70px;
  padding: 50px;
  display: flex;
  ${mobileMd({ padding: '10px', flexDirection: 'column' })}
`;
const ImgContainer = styled.div`
  flex: 1;
`;
const Image = styled.img`
  width: 100%;
  height: 60vh;
  object-fit: cover;
  ${mobile({ height: '40vh' })}
`;
const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobileLg({ padding: '0 0 0 20px' })}
  ${mobile({ padding: '10px' })}
`;
const Title = styled.h1`
  font-weight: 200;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 60px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 25px;
  }
`;
const Desc = styled.p`
  margin: 20px 0px;
  color: ${(props) => props.theme.colors.gray4};
`;

const Nosotros = () => {
  const nosotros = useSelector((state) => state.info.nosotros);
  const dispatch = useDispatch();

  useEffect(() => {
    getInfoNosotros(dispatch);
  }, [dispatch]);

  return (
    <Layout
      title='Sobre Nosotros'
      desc='Decoracion de hogar Pure, productos de calidad'
    >
      {nosotros?.map((item, index) => (
        <Wrapper key={index}>
          <ImgContainer>
            <Image
              src={
                nosotros.length > 0
                  ? item?.img
                  : 'https://images.pexels.com/photos/1005058/pexels-photo-1005058.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
              }
            />
          </ImgContainer>
          <InfoContainer>
            <Title>{nosotros.length > 0 ? item?.title : 'Titulo'} </Title>
            <Desc>
              {nosotros.length > 0 ? item?.description : 'Descripcion'}
            </Desc>
          </InfoContainer>
        </Wrapper>
      ))}
      <Footer />
    </Layout>
  );
};

export default Nosotros;
