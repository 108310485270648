import React, { useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { publicRequest } from 'config/clienteAxios';
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';

const Error = styled.div`
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
  font-size: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 300px;
`;
const Alerta = styled.div`
  color: red;
  font-size: 12px;
  margin-left: 10px;
`;
const Success = styled.div`
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
  font-size: 12px;
  padding: 10px 16px;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 300px;
`;

const NewUser = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState('');
  //   const history = useHistory();

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      dni: '',
      email: '',
      address: '',
      tel: '',
      city: '',
      prov: '',
      codPostal: '',
      password: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Solo se permiten letras para este campo.')
        .min(3, 'Tu nombre es demasiado corto.')
        .required('El campo es requerido.'),
      lastName: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Solo se permiten letras para este campo.')
        .min(3, 'Tu apellido es demasiado corto.')
        .required('El campo es requerido.'),
      dni: Yup.string()
        .min(8, 'El dni debe contener al menos 8 digitos.')
        .required('El campo es requerido'),
      email: Yup.string()
        .email('El email no es valido')
        .required('El campo es requerido.'),
      address: Yup.string()
        .min(3, 'Tu direccion es demasiado corta.')
        .max(50, 'Debe tener como máximo 50 caracteres.')
        .required('El campo es requerido.'),
      tel: Yup.string()
        .matches(/^[0-9\s]+$/, 'Solo se permiten numeros para este campo.')
        .min(9, 'El telefono debe contener al menos 9 digitos.')
        .required('El campo es requerido'),
      city: Yup.string().required('El campo es requerido.'),
      prov: Yup.string().required('El campo es requerido.'),
      codPostal: Yup.string()
        .matches(/^[0-9\s]+$/, 'Solo se permiten numeros para este campo.')
        .max(5, 'Debe tener como máximo 5 caracteres.')
        .required('El campo es requerido.'),
      password: Yup.string()
        .min(8, 'La contraseña debe contener al menos 8 caracteres')
        .required('El campo es requerido.'),
    }),
    onSubmit: (valores, { resetForm }) => {
      registerUser(valores);
      resetForm({ valores: '' }); // Limpiar el formulario
    },
  });

  const registerUser = (inputs) => {
    const register = async () => {
      const res = await publicRequest.post('/auth/registrarse', inputs);
      setSuccess(res.data.success);
      setError(res.data.success);
      setMsg(res.data.msg);
    };
    register();

    setTimeout(() => {
      setSuccess(false);
      setError(true);
      //   history.push('/usuarios');
    }, 4000);
  };

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Nuevo Usuario</Title>
          <Form onSubmit={formik.handleSubmit}>
            <BoxInput>
              <Label>Nombre</Label>
              <Input
                type='text'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.name && formik.errors.name ? (
              <Alerta>{formik.errors.name}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Apellidos</Label>
              <Input
                type='text'
                name='lastName'
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.lastName && formik.errors.lastName ? (
              <Alerta>{formik.errors.lastName}</Alerta>
            ) : null}

            <BoxInput>
              <Label>DNI</Label>
              <Input
                type='text'
                name='dni'
                value={formik.values.dni}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.dni && formik.errors.dni ? (
              <Alerta>{formik.errors.dni}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Email</Label>
              <Input
                type='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.email && formik.errors.email ? (
              <Alerta>{formik.errors.email}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Dirección</Label>
              <Input
                type='text'
                name='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.address && formik.errors.address ? (
              <Alerta>{formik.errors.address}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Teléfono</Label>
              <Input
                type='tel'
                name='tel'
                value={formik.values.tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.tel && formik.errors.tel ? (
              <Alerta>{formik.errors.tel}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Ciudad</Label>
              <Input
                type='text'
                name='city'
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.city && formik.errors.city ? (
              <Alerta>{formik.errors.city}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Provincia</Label>
              <Input
                type='text'
                name='prov'
                value={formik.values.prov}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.prov && formik.errors.prov ? (
              <Alerta>{formik.errors.prov}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Código Postal</Label>
              <Input
                type='text'
                name='codPostal'
                value={formik.values.codPostal}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.codPostal && formik.errors.codPostal ? (
              <Alerta>{formik.errors.codPostal}</Alerta>
            ) : null}

            <BoxInput>
              <Label>Contraseña</Label>
              <Input
                type='password'
                name='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </BoxInput>
            {formik.touched.password && formik.errors.password ? (
              <Alerta>{formik.errors.password}</Alerta>
            ) : null}

            {success && (
              <Success>
                {' '}
                <CheckCircleOutline style={{ marginRight: '10px' }} /> {msg}
              </Success>
            )}
            {!error && (
              <Error>
                {' '}
                <ErrorOutline style={{ marginRight: '10px' }} /> {msg}
              </Error>
            )}

            <BoxButtons>
              <Button
                type='submit'
                color='#fff'
                padding='10px 15px'
                margin='10px 10px 0 0'
              >
                Crear
              </Button>
              <Link to='/usuarios' className='link'>
                <Button
                  bgColor='cancel'
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default NewUser;
