import { createSlice } from '@reduxjs/toolkit';
import { addProduct, deleteProduct, updateProduct } from './productThunks';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    // ------------------ OBTENER PRODUCTOS ------------------
    getProductStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getProductSuccess: (state, action) => {
      state.isFetching = false;
      state.products = action.payload;
    },
    getProductFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // ------------------ AGREGAR PRODUCTO ------------------
    addProductStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addProductSuccess: (state, action) => {
      state.isFetching = false;
      state.products.unshift(action.payload);
    },
    addProductFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // ------------------ ACTUALIZAR PRODUCTO ------------------
    updateProductStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateProductSuccess: (state, action) => {
      state.isFetching = false;
      state.products[
        state.products.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.product;
    },
    updateProductFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // ------------------ ELIMINAR PRODUCTO ------------------
    deleteProductStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteProductSuccess: (state, action) => {
      state.isFetching = false;
      state.products.splice(
        state.products.findIndex((item) => item.id === action.payload),
        1
      );
    },
    deleteProductFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProduct.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isFetching = false;
        const { msg } = state.products;
        const { id } = action.payload;
        const productsAux = JSON.parse(JSON.stringify(state.products.products));
        const arrayAux = productsAux.filter(
          (product) => product.id !== Number(id)
        );
        const newProductsState = { msg, products: arrayAux };
        state.products = newProductsState;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload;
      });

    builder
      .addCase(addProduct.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isFetching = false;
        const { msg } = state.products;
        const productsAux = JSON.parse(JSON.stringify(state.products.products));
        productsAux.push(action.payload.newProduct);
        const newProductsState = { msg, products: productsAux };
        state.products = newProductsState;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload;
      });
  },
});

export const {
  getProductStart,
  getProductSuccess,
  getProductFailure,
  deleteProductStart,
  deleteProductSuccess,
  deleteProductFailure,
  updateProductStart,
  updateProductSuccess,
  updateProductFailure,
  addProductStart,
  addProductSuccess,
  addProductFailure,
} = productSlice.actions;

export default productSlice.reducer;
