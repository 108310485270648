import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { publicRequest, userRequest } from 'config/clienteAxios';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Error } from '@material-ui/icons';

const Estilos = () => {
  const accessToken = JSON.parse(localStorage.getItem('token_user'));
  const [data, setData] = useState({
    colors: [],
  });

  const [colors, setColors] = useState({
    colorPrimary: '',
    colorSecondary: '',
  });

  const getInfo = async () => {
    try {
      const res = await publicRequest.get('/infotienda', {
        headers: { token: accessToken },
      });
      const info = res.data?.info[0];
      setData(info);
      setColors(
        info.colors
          ? info.colors
          : { colorPrimary: '#FFFFFF', colorSecondary: '#000000' }
      );
    } catch (error) {}
  };
  const handleColorChange = (e) => {
    setColors((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const producto = { ...data, colors: colors };

    try {
      const updateInfo = async () => {
        const res = await userRequest.put(`/infotienda/${data.id}`, producto, {
          headers: { token: accessToken },
        });
        if (res.data.success) {
          alertSuccess(res.data.msg);
        }
      };
      updateInfo();
    } catch (error) {}
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Estilos de colores</Title>
          {data ? (
            <Form>
              <BoxInput>
                <Label>Color primario</Label>
                <Input
                  type='text'
                  name='colorPrimary'
                  value={colors?.colorPrimary}
                  placeholder='#FFFFFF'
                  onChange={handleColorChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Color secundario</Label>
                <Input
                  type='text'
                  name='colorSecondary'
                  placeholder='#000000'
                  value={colors?.colorSecondary}
                  onChange={handleColorChange}
                />
              </BoxInput>

              <BoxButtons>
                <Button
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                  onClick={handleFormSubmit}
                >
                  Guardar
                </Button>
              </BoxButtons>
            </Form>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                marginTop: '0.5rem',
                color: 'red',
              }}
            >
              <Error />
              <p
                style={{
                  color: 'red',
                }}
              >
                Debe cargar primero la configuración para cargar 'Estilos'
              </p>
            </div>
          )}
        </Wrapper>
      </div>
    </>
  );
};

export default Estilos;
