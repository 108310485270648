import React from 'react';
import { currencyFormat } from 'utils/data-conversion';
import { Document, Page, View, Text } from '@react-pdf/renderer';
// import Logo from "../../../assets/img/logoAiredeLola.png";

const OrderPDF = ({ order }) => {
  // const { _id, amount, userId, products } = order;

  const {
    // _id,
    orderNum,
    date,
    hour,
    subtotal,
    shipment,
    additional,
    total,
    discountCoupon,
    shipmentForm,
    paymentForm,
    // status,
    userId,
    products,
    userInvite,
  } = order;

  const handleMostarDesc = (total) => {
    if (discountCoupon) {
      if (discountCoupon.tipo === 'porcentaje') {
        const des = (total * discountCoupon.descuento) / 100;
        const res = des.toFixed(2);
        return res;
      }
      if (discountCoupon.tipo === 'importe') {
        const res = discountCoupon.descuento;
        return res;
      }
    }
    return total;
  };

  return (
    <Document>
      <Page size='A4'>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20 0',
            fontSize: '12px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Text style={{ margin: "20 auto" }}>
              <Image
                src={Logo}
                alt="Logo Aire De Lola"
              />
            </Text> */}
            <Text style={{ margin: '20 auto' }}>
              {' '}
              <span>Detalles de Orden</span>
            </Text>
          </div>

          <Text style={{ marginLeft: '20', marginBottom: '5' }}>
            {' '}
            <span>Nombre del Dueño</span>
            {'                           '}
            <span>Nombre y apellidos:</span>{' '}
            {userId ? order.User?.name : userInvite?.name}{' '}
            {userId ? order.User?.lastName : userInvite?.lastName}
          </Text>
          <Text style={{ marginLeft: '20', marginBottom: '5' }}>
            {' '}
            <span>Aqui va el DNI</span>
            {
              '                                                                  '
            }
            <span>Dirección:</span>{' '}
            {userId ? order.User?.address : userInvite?.address}{' '}
          </Text>
          <Text style={{ marginLeft: '20', marginBottom: '5' }}>
            {' '}
            <span>Aqui va la direccion de la tienda</span>
            {'                '}
            <span>
              {userId ? order.User?.codPostal : userInvite?.codPostal}
            </span>
          </Text>
          <Text style={{ marginLeft: '20', marginBottom: '5' }}>
            {' '}
            <span>Teléfono:</span> Aqui va el telefono
            {'                                                   '}
            {userId ? order.User?.city : userInvite?.city} -{' '}
            {userId ? order.User?.prov : userInvite?.prov}{' '}
          </Text>
          <Text style={{ marginLeft: '20', marginBottom: '5' }}>
            {' '}
            {
              '                                                                                    '
            }
            <span>DNI:</span> {userId ? order.User?.dni : userInvite?.dni}{' '}
          </Text>
          <Text style={{ marginLeft: '20', marginBottom: '5' }}>
            {' '}
            <span>N° Pedido:</span> {orderNum}
            {'                                                              '}
            <span>Teléfono:</span> {userId ? order.User?.tel : userInvite?.tel}{' '}
          </Text>
          <Text style={{ marginLeft: '20', marginBottom: '5' }}>
            {' '}
            <span>date:</span> {date} <span>hour:</span> {hour}
            {'                              '}
            {userId ? order.User?.email : userInvite?.email}{' '}
          </Text>

          <Text style={{ margin: '15 auto' }}>
            {' '}
            <span>Productos</span>{' '}
          </Text>
          <div>
            <Text style={{ marginLeft: '20', marginBottom: '5' }}>
              {' '}
              <span>Código</span> {'                   '}
              <span>Descripción</span> {'                             '}
              <span>Medidas</span> {'      '}
              <span>Color</span> {'      '}
              <span>Cant.</span> {'      '}
              <span>Precio</span> {'      '}
              <span>Subtotal</span>
            </Text>
            {products?.map((product, index) => (
              <div key={index} style={{ margin: '5 20' }}>
                <Text>
                  {' '}
                  <span>{product.cod}</span> {'            '}
                  <span>{product.name}</span>{' '}
                  {'                               '}
                  <span>{product.measures}</span> {'           '}
                  <span>{product.color}</span> {'        '}
                  <span>{product.quantity}</span> {'        '}
                  <span>{currencyFormat(product.price)}</span> {'        '}
                  <span>{currencyFormat(subtotal)}</span>
                </Text>
              </div>
            ))}
          </div>
          <div style={{ margin: '20 20 0' }}>
            <Text>
              {' '}
              <span>Forma de Pago:</span> {paymentForm}
            </Text>
            {shipmentForm === 'retiradaentienda' ? (
              <Text>
                <span>Retirada en tienda</span>
              </Text>
            ) : (
              <Text>
                <span>shipment: {currencyFormat(shipment)}</span>
              </Text>
            )}
            {paymentForm === 'contrareembolso' && (
              <Text>
                {' '}
                <span>Adic. Contrareembolso:</span> {currencyFormat(additional)}
              </Text>
            )}
            {discountCoupon === 0 ? null : (
              <Text>
                {' '}
                <span>Cupón descuento:</span> -
                {currencyFormat(handleMostarDesc(subtotal))}
              </Text>
            )}
          </div>

          <Text style={{ margin: '10 auto' }}>
            {' '}
            <span>Total:</span> {currencyFormat(total)}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrderPDF;
