import React, { useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { alertError, alertSuccess } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Select,
  TextArea,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import { addProduct } from 'features/product/productThunks';

const NewProduct = () => {
  const products = useSelector((state) => state.product.products);
  const [cod, setCod] = useState('');
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [categories, setCategories] = useState('');
  const categorias = useSelector((state) => state.category.categories);

  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.product);
  const history = useHistory();

  const [stock, setStock] = useState({}); // o lo cambio a []
  const [details, setDetails] = useState([]);

  const handleInputChange = (e) => {
    setStock((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const addNewProduct = async (formData) => {
    await dispatch(addProduct(formData));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Comprobar que los campos no esten vacios
    if ([cod, title, desc, price, discount, categories].includes('')) {
      alertError('Todos los campos son obligatorios');
      return;
    }
    if (file === null) {
      alertError('Debe agregar una imagen');
      return;
    }
    if (details.length === 0) {
      alertError('Todos los campos son obligatorios');
      return;
    }

    let formData = new FormData();

    formData.append('cod', cod);
    formData.append('title', title);
    formData.append('desc', desc);
    formData.append('price', price);
    formData.append('discount', discount);
    formData.append('categories', categories);

    for (let index = 0; index < file.length; index++) {
      formData.append('images', file[index]);
    }

    for (let index = 0; index < details.length; index++) {
      formData.append('detail', JSON.stringify(details[index]));
    }

    try {
      addNewProduct(formData);
      alertSuccess('¡Producto agregado!');
      history.push('/productos');
    } catch (error) {
      console.log(error);
      alertError('¡Error al agregar!');
    }
  };

  function limpiar() {
    document.getElementById('del1').value = '';
    document.getElementById('del2').value = '';
    // document.getElementById("del3").value = "";
    document.getElementById('del4').value = '';
  }

  const handleAddDetails = (e) => {
    e.preventDefault();

    details.push(stock);
    setDetails(details);

    // Limpiar los campos
    limpiar();

    document.getElementById('listado').insertRow(-1).innerHTML =
      `<tr>` +
      `<td>${stock.measures}</td>` +
      `<td>${stock.color}</td>` +
      `<td>${stock.stock}</td>` +
      `</tr>`;
  };

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Nuevo Producto</Title>
          <Form onSubmit={handleFormSubmit}>
            <BoxInput>
              <Label>Código del Producto</Label>
              <Input
                name='cod'
                type='text'
                onChange={(e) => setCod(e.target.value)}
              />
              {cod &&
                products.products.map((i) => {
                  return (
                    i.cod === cod && (
                      <p style={{ color: 'red', fontSize: '12px' }}>
                        *El codigo ya existe
                      </p>
                    )
                  );
                })}
            </BoxInput>
            <BoxInput>
              <Label>Imagen</Label>
              <Input
                type='file'
                name='images'
                multiple
                accept='.jpg, .jpeg, .png'
                onChange={(e) => setFile(e.target.files)}
                style={{ border: 'none' }}
              />
              <span style={{ fontSize: '12px' }}>
                *Peso de cada imagen (2mb maximo)
              </span>
            </BoxInput>
            <BoxInput>
              <Label>Nombre</Label>
              <Input
                name='title'
                type='text'
                onChange={(e) => setTitle(e.target.value)}
              />
            </BoxInput>
            <BoxInput>
              <Label>Descripción</Label>
              <TextArea
                name='desc'
                cols='30'
                rows='8'
                onChange={(e) => setDesc(e.target.value)}
              ></TextArea>
            </BoxInput>
            <BoxInput>
              <Label>Precio</Label>
              <Input
                name='price'
                type='number'
                min='0'
                onChange={(e) => setPrice(e.target.value)}
                step='any'
              />
            </BoxInput>
            <BoxInput>
              <Label>Descuento</Label>
              <Input
                name='discount'
                type='number'
                min='0'
                max='100'
                onChange={(e) => setDiscount(e.target.value)}
              />
            </BoxInput>
            <BoxInput>
              <Label>Categorias</Label>
              {categorias.length >= 1 ? (
                <Select
                  name='categories'
                  onChange={(e) => setCategories(e.target.value)}
                  style={{ height: '40px', padding: '5px' }}
                >
                  <option>Seleccionar</option>
                  {categorias.map((c) => (
                    <option value={c.name} key={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <span style={{ fontSize: '14px', color: 'red' }}>
                  *No hay categorias. Debe crear primero las categorias.
                </span>
              )}
            </BoxInput>

            {/* =============== AGREGAR DETALLES DEL PRODUCTO ===============  */}
            <Label style={{ margin: '10px 0 0' }}>Detalles</Label>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', margin: '10px 0' }}>
                <Input
                  type='text'
                  name='measures'
                  id='del1'
                  w='100px'
                  margin='0 5px 0 0'
                  placeholder='Medidas'
                  onChange={handleInputChange}
                />{' '}
                <Input
                  type='text'
                  name='color'
                  id='del2'
                  w='150px'
                  margin='0 5px 0 0'
                  placeholder='Color'
                  onChange={handleInputChange}
                />{' '}
                {/* <Input
                  type="number"
                  name="year"
                  id="del3"
                  w="55px"
                  margin="0 5px 0 0"
                  placeholder="Año"
                  onChange={handleInputChange}
                /> */}
                <Input
                  type='number'
                  name='stock'
                  id='del4'
                  w='55px'
                  margin='0 5px 0 0'
                  placeholder='Stock'
                  onChange={handleInputChange}
                />
                <button
                  onClick={handleAddDetails}
                  style={{
                    padding: '0 10px',
                    cursor: 'pointer',
                    backgroundColor: '#3bb077',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Add />
                </button>
              </div>
            </div>

            {/* =============== LISTADO DE LO QUE AGREGUE ===============  */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Label>Listado</Label>
              <table style={{ width: '50%' }}>
                <thead>
                  <tr>
                    <th align='left' style={{ color: '#808080' }}>
                      Medidas
                    </th>
                    <th align='left' style={{ color: '#808080' }}>
                      Color
                    </th>
                    <th align='left' style={{ color: '#808080' }}>
                      Stock
                    </th>
                  </tr>
                </thead>
                <tbody
                  id='listado'
                  style={{ fontSize: '14px', fontWeight: '500' }}
                ></tbody>
              </table>
            </div>

            <BoxButtons>
              <Button
                color='#fff'
                padding='10px 15px'
                margin='10px 10px 0 0'
                type='submit'
              >
                Crear
              </Button>
              <Link to='/productos' className='link'>
                <Button
                  bgColor='cancel'
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
            {error && <div>Error al registrar el producto</div>}
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default NewProduct;
