import { useState } from 'react';
import styled from 'styled-components';
import { mobile } from 'styles/responsive';
import { publicRequest } from 'config/clienteAxios';
import {
  ArrowBack,
  EmailOutlined,
  PersonOutline,
  PhoneAndroid,
  CheckCircleOutline,
  ErrorOutline,
  FindInPage,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #2d3142;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 400px;
  padding: 20px;
  margin: 20px 0;
  background-color: white;
  border-radius: 10px;
  ${mobile({ width: '80%' })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px auto 10px;
  ${mobile({ width: '100%' })}
`;

const Error = styled.div`
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
  font-size: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const Alerta = styled.div`
  color: red;
  font-size: 12px;
  margin-left: 10px;
`;

const BoxInput = styled.div`
  display: flex;
  border: 2px solid #eee;
  border-radius: 10px;
  margin-top: 10px;
`;
const Icon = styled.i`
  color: gray;
  display: flex;
  align-items: center;
  margin: 0 5px;
`;
const Success = styled.div`
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
  font-size: 12px;
  padding: 10px 16px;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const BotonArrepentimiento = () => {
  // const [inputs, setInputs] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState('');

  // const handleChange = (e) => {
  //   setInputs((prev) => {
  //     return { ...prev, [e.target.name]: e.target.value };
  //   });
  // }

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      tel: '',
      motivo: '',
      orden: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('El campo es requerido.'),
      email: Yup.string()
        .email('El email no es valido')
        .required('El campo es requerido.'),
      tel: Yup.string()
        .required('El campo es requerido')
        .min(10, 'El telefono debe contener al menos 8 caracteres'),
      motivo: Yup.string().required('El campo es requerido.'),
      orden: Yup.string().required('El campo es requerido.'),
    }),
    onSubmit: (valores, { resetForm }) => {
      registerUser(valores);
      resetForm({ valores: '' }); // Limpiar el formulario
    },
  });

  const registerUser = (inputs) => {
    // e.preventDefault();

    const register = async () => {
      const res = await publicRequest.post('/cancelacioncompra', inputs);
      setSuccess(res.data.success);
      setError(res.data.success);
      setMsg(res.data.msg);
    };
    register();

    setTimeout(() => {
      setSuccess(false);
      setError(true);
    }, 4000);

    // e.target.reset();
  };

  return (
    <Container>
      <Wrapper>
        <Link to='/' className='link'>
          <ArrowBack />
        </Link>
        <Title>Cancelación de Compra</Title>
        <p style={{ margin: '10px 0', fontSize: '14px', color: '#000' }}>
          Déjanos tus datos para iniciar la cancelación de la compra y dentro de
          las 24hs un agente se contactara contigo. <br />
          <b>
            Recorda que tenés 10 dias corridos desde que adquiriste el producto
          </b>{' '}
          para iniciar el proceso de cancelación.
        </p>

        {success && (
          <Success>
            {' '}
            <CheckCircleOutline style={{ marginRight: '10px' }} /> {msg}
          </Success>
        )}
        {!error && (
          <Error>
            {' '}
            <ErrorOutline style={{ marginRight: '10px' }} /> {msg}
          </Error>
        )}

        <Form onSubmit={formik.handleSubmit}>
          <BoxInput>
            <Icon>
              <PersonOutline />
            </Icon>
            <Input
              type='text'
              name='name'
              placeholder='Nombre y Apellido'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.name && formik.errors.name ? (
            <Alerta>{formik.errors.name}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <EmailOutlined />
            </Icon>
            <Input
              type='email'
              name='email'
              placeholder='Correo electrónico'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.email && formik.errors.email ? (
            <Alerta>{formik.errors.email}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <PhoneAndroid />
            </Icon>
            <Input
              type='tel'
              name='tel'
              placeholder='Teléfono (cod. área + número)'
              value={formik.values.tel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.tel && formik.errors.tel ? (
            <Alerta>{formik.errors.tel}</Alerta>
          ) : null}

          <BoxInput>
            <select
              name='motivo'
              style={{
                margin: '0 10px 0',
                border: 'none',
                borderRadius: '10px',
                padding: '10px',
                outline: 'none',
                width: '100%',
              }}
              value={formik.values.motivo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option>Seleccione un motivo...</option>
              <option value='Falla del producto'>Falla del producto</option>
              <option value='El producto no me satisface'>
                El producto no me satisface
              </option>
              <option value='No es lo que pedi'>No es lo que pedi</option>
              <option value='Demora en la entrega'>Demora en la entrega</option>
            </select>
          </BoxInput>
          {formik.touched.motivo && formik.errors.motivo ? (
            <Alerta>{formik.errors.motivo}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <FindInPage />
            </Icon>
            <Input
              type='text'
              name='orden'
              placeholder='N° Orden de Compra'
              value={formik.values.orden}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.orden && formik.errors.orden ? (
            <Alerta>{formik.errors.orden}</Alerta>
          ) : null}

          <Button type='submit'>Enviar</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default BotonArrepentimiento;
