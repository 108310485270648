import { useState, useEffect } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Select,
  TextArea,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import styles from './product.module.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Add, DeleteOutline, Publish } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { publicRequest } from 'config/clienteAxios';
import { updateProduct } from 'api/apiCalls';
import Spinner from 'components/spinner/Spinner';

const Product = () => {
  const location = useLocation();
  const productId = location.pathname.split('/')[2];
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = JSON.parse(localStorage.getItem('token_user'));
  // const [categorias, setCategorias] = useState([]);
  const [data, setData] = useState({
    cod: '',
    img: [],
    title: '',
    desc: '',
    price: '',
    discount: '',
    categories: '',
    details: [],
  });
  const [details, setDetails] = useState([]);
  const [file, setFile] = useState(null);
  const [stock, setStock] = useState([]);
  const [newDetails, setNewDetails] = useState([]);
  const categorias = useSelector((state) => state.category.categories);

  // const product = useSelector((state) =>
  //   state.product.products.find((product) => product._id === productId)
  // );

  const getProductId = async () => {
    try {
      const res = await publicRequest.get(`productos/find/${productId}`, {
        headers: { token: accessToken },
      });
      setData(res.data.product);
      setDetails(res.data.product.details);
      setNewDetails(res.data.product.details);
    } catch (error) {}
  };

  // const getCategories = async () => {
  //   try {
  //     const res = await publicRequest.get("/categorias");
  //     setCategorias(res.data);
  //   } catch (error) {}
  // };

  const handleInputChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleInputChange2 = (e, index) => {
    let tallaIndex = details.indexOf(details[index]);
    let newT = [];

    details.map((t, index) => {
      if (tallaIndex === index) {
        let objkeys = Object.keys(t);
        if (objkeys.includes(e.target.name)) {
          if (e.target.name === 'measures') {
            newT.push({
              [e.target.name]: e.target.value,
              color: t.color,
              stock: t.stock,
            });
          } else if (e.target.name === 'color') {
            newT.push({
              measures: t.measures,
              [e.target.name]: e.target.value,
              stock: t.stock,
            });
          } else if (e.target.name === 'stock') {
            newT.push({
              measures: t.measures,
              color: t.color,
              [e.target.name]: e.target.value,
            });
          } else {
            newT.push({
              measures: t.measures,
              color: t.color,
              stock: t.stock,
            });
          }
        }
      } else {
        newT.push({
          measures: t.measures,
          color: t.color,
          stock: t.stock,
        });
      }
      return newT;
    });

    setDetails(newT);
    setNewDetails(newT);
  };

  useEffect(() => {
    getProductId();
    // getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Desestructurar data actualizada
    const { cod, title, desc, price, discount, categories } = data;

    let formData = new FormData();

    formData.append('cod', cod);
    formData.append('title', title);
    formData.append('desc', desc);
    formData.append('price', price);
    formData.append('discount', discount);
    formData.append('categories', categories);

    for (let index = 0; index < file?.length; index++) {
      formData.append('images', file[index]);
    }
    for (let index = 0; index < newDetails?.length; index++) {
      formData.append('detail', JSON.stringify(newDetails[index]));
    }

    updateProduct(productId, formData, dispatch);

    alertSuccess('¡Producto actualizado!');
    history.push('/productos');
  };

  const handleInputChange3 = (e) => {
    setStock((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  function limpiar() {
    document.getElementById('del1').value = '';
    document.getElementById('del2').value = '';
    // document.getElementById("del3").value = "";
    document.getElementById('del4').value = '';
  }

  const handleClickAdd = (e) => {
    e.preventDefault();

    // Array que contiene todas los detalles del producto y es el que se mandara
    newDetails.push(stock);
    setNewDetails(newDetails);

    limpiar();
  };

  const handleDeleteTalla = (e, posicion) => {
    e.preventDefault();

    // Eliminar un detalle
    const del = details.filter((i, index) => index !== posicion);
    setDetails(del);
    setNewDetails(del);
  };

  const imagenes = data.img;
  // const tallas = data.tallas;

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Editar Producto</Title>
          <Form>
            {Object.keys(data).length !== 0 ? (
              <>
                <div className={styles.productForm}>
                  <div className={styles.productFormLeft}>
                    <BoxInput>
                      <Label>Codigo del Producto</Label>
                      <Input
                        type='text'
                        name='cod'
                        value={data?.cod || ''}
                        disabled
                        onChange={handleInputChange}
                      />
                    </BoxInput>

                    <BoxInput>
                      <Label>Nombre Producto</Label>
                      <Input
                        type='text'
                        name='title'
                        value={data?.title || ''}
                        onChange={handleInputChange}
                      />
                    </BoxInput>

                    <BoxInput>
                      <Label>Descripcion</Label>
                      <TextArea
                        name='desc'
                        cols='40'
                        rows='4'
                        value={data?.desc || ''}
                        onChange={handleInputChange}
                      ></TextArea>
                    </BoxInput>

                    <BoxInput>
                      <Label>Precio</Label>
                      <Input
                        type='text'
                        name='price'
                        value={data?.price || ''}
                        onChange={handleInputChange}
                      />
                    </BoxInput>

                    <BoxInput>
                      <Label>Descuento</Label>
                      <Input
                        type='number'
                        name='discount'
                        value={data?.discount || 0}
                        onChange={handleInputChange}
                        min='0'
                        max='100'
                      />
                    </BoxInput>

                    <BoxInput>
                      <Label>Categorias</Label>
                      <Select
                        name='categories'
                        id='categories'
                        value={data?.categories || ''}
                        onChange={handleInputChange}
                        style={{ height: '40px', padding: '5px' }}
                      >
                        {categorias.map((c) => (
                          <option key={c.id}>{c.name}</option>
                        ))}
                      </Select>
                    </BoxInput>

                    <BoxInput>
                      <Label>Detalles</Label>
                    </BoxInput>
                    {details?.map((i, index) => (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                        key={index}
                      >
                        <Input
                          type='text'
                          name='measures'
                          value={i.measures}
                          w='100px'
                          margin='0 5px 0 0'
                          onChange={(e) => handleInputChange2(e, index)}
                        />{' '}
                        <Input
                          type='text'
                          name='color'
                          w='150px'
                          margin='0 5px 0 0'
                          value={i.color}
                          onChange={(e) => handleInputChange2(e, index)}
                        />{' '}
                        {/* <Input
                          type="number"
                          name="year"
                          w="50px"
                          margin="0 5px 0 0"
                          value={i.year}
                          onChange={(e) => handleInputChange2(e, index)}
                        /> */}
                        <Input
                          type='number'
                          name='stock'
                          w='50px'
                          margin='0 5px 0 0'
                          value={i.stock}
                          onChange={(e) => handleInputChange2(e, index)}
                        />
                        <button
                          style={{
                            border: 'none',
                            background: 'transparent',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleDeleteTalla(e, index)}
                        >
                          <DeleteOutline style={{ color: 'red' }} />
                        </button>
                      </div>
                    ))}

                    {/* SECCION PARA AGREGAR UNA TALLA NUEVA */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <Input
                        type='text'
                        name='measures'
                        id='del1'
                        w='100px'
                        margin='0 5px 0 0'
                        placeholder='Medidas'
                        onChange={handleInputChange3}
                      />{' '}
                      <Input
                        type='text'
                        name='color'
                        id='del2'
                        w='150px'
                        margin='0 5px 0 0'
                        placeholder='Color'
                        onChange={handleInputChange3}
                      />{' '}
                      {/* <Input
                        type="number"
                        name="year"
                        id="del3"
                        w="50px"
                        margin="0 5px 0 0"
                        placeholder="Año"
                        onChange={handleInputChange3}
                      /> */}
                      <Input
                        type='number'
                        name='stock'
                        id='del4'
                        w='50px'
                        margin='0 5px 0 0'
                        placeholder='Stock'
                        onChange={handleInputChange3}
                      />
                      <button
                        onClick={handleClickAdd}
                        style={{
                          padding: '9px',
                          cursor: 'pointer',
                          backgroundColor: '#3bb077',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Add />
                      </button>
                    </div>
                  </div>

                  {/* SECCION PARA CAMBIAR IMAGEN */}
                  <div className={styles.productFormRight}>
                    <div className={styles.productUpload}>
                      {imagenes?.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt=''
                          className={styles.productUploadImg}
                        />
                      ))}
                      <Label htmlFor='file'>
                        <Publish style={{ cursor: 'pointer' }} />
                      </Label>
                      <Input
                        type='file'
                        id='file'
                        multiple
                        onChange={(e) => setFile(e.target.files)}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </div>
                </div>
                <BoxButtons>
                  <Button
                    color='#fff'
                    padding='10px 15px'
                    margin='10px 10px 0 0'
                    onClick={handleFormSubmit}
                  >
                    Actualizar
                  </Button>
                  <Link to='/productos' className='link'>
                    <Button
                      bgColor='cancel'
                      color='#fff'
                      padding='10px 15px'
                      margin='10px 10px 0 0'
                    >
                      Cancelar
                    </Button>
                  </Link>
                </BoxButtons>
              </>
            ) : (
              <Spinner />
            )}
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default Product;
