import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { alertDelete } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  TextArea,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { userRequest } from 'config/clienteAxios';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';

const Card = styled.div`
  width: 630px;
  margin: 50px 0;
  @media (max-width: 485px) {
    width: 350px;
  }
`;
const CarDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  margin-top: 10px;
  padding: 0 10px;
`;
const Detail = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
  .imagen {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border: 1px solid #eee;
  }
  .desc1 {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .desc2 {
    white-space: nowrap;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 485px) {
      display: none;
    }
  }
`;

const BtnDetail = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 0 5px;
  }
`;

const InfoNosotros = () => {
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const handleInputChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const getData = async () => {
    const res = await userRequest.get('/infotienda/nosotros', {
      headers: { token: accessToken },
    });
    setData(res.data.data);
  };

  const deleteData = async (id) => {
    await userRequest.delete(`/infotienda/nosotros/${id}`, {
      headers: { token: accessToken },
    });
    getData();
  };

  const delData = async (id) => {
    alertDelete(deleteData, id, 'Informacion eliminada');
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { title, desc } = inputs;

    let formData = new FormData();

    formData.append('title', title);
    formData.append('desc', desc);

    for (let index = 0; index < file.length; index++) {
      formData.append('image', file[index]);
    }

    try {
      const newInfo = async () => {
        const res = await userRequest.post('/infotienda/nosotros', formData, {
          headers: { token: accessToken },
        });
        getData();
      };
      newInfo();
    } catch (error) {
      console.log(error);
    }
    setFile(null);
    e.target.reset();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Sección Nosotros</Title>
          <Form onSubmit={handleFormSubmit}>
            <BoxInput>
              <Label>Imagen</Label>
              <Input
                type='file'
                name='image'
                accept='.jpg, .jpeg, .png'
                onChange={(e) => setFile(e.target.files)}
                style={{ border: 'none' }}
              />
              <span style={{ fontSize: '12px', marginBottom: '10px' }}>
                *Peso de cada imagen (2mb maximo)
              </span>
            </BoxInput>

            <BoxInput>
              <Label>Titulo</Label>
              <Input
                type='text'
                name='title'
                placeholder='Ingrese un titulo'
                style={{ marginBottom: '10px' }}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Descripcion</Label>
              <TextArea
                name='desc'
                id='desc'
                cols='30'
                rows='8'
                placeholder='Ingrese una descripcion'
                onChange={handleInputChange}
              ></TextArea>
            </BoxInput>

            <BoxButtons>
              <Button
                type='submit'
                disabled={file && inputs.title && inputs.desc ? false : true}
                className='disabled'
                color='#fff'
                padding='10px 15px'
                margin='10px 0'
              >
                Agregar
              </Button>
            </BoxButtons>
          </Form>
          <Card>
            {data.data?.map((item, index) => (
              <CarDetails key={index}>
                <Detail>
                  <img src={item.img} alt='' className='imagen' />
                  <span className='desc1'>{item.title}</span>
                  <span className='desc2'>{item.desc}</span>
                </Detail>
                <BtnDetail>
                  <Link to={`/edit/info-nosotros/${item.id}`}>
                    <button
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                      }}
                    >
                      <EditOutlined style={{ color: 'green' }} />
                    </button>
                  </Link>
                  <button
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                    }}
                    onClick={() => delData(item.id)}
                  >
                    <DeleteOutline style={{ color: 'red' }} />
                  </button>
                </BtnDetail>
              </CarDetails>
            ))}
          </Card>
        </Wrapper>
      </div>
    </>
  );
};

export default InfoNosotros;
