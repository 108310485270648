import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { userRequest } from 'config/clienteAxios';
import { Edit } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Layout from 'components/layouts/Layout';
import {
  Alerta,
  Btn,
  Container,
  Details,
  Input,
  Span,
  Subtitle,
  Title,
  Wrapper,
} from './ProfileStyles';

const Profile = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { id } = user;
  const verifyEmail = useSelector(
    (state) => state.user.currentUser?.isVerified
  );

  const [usuario, setUsuario] = useState([]);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const getUserId = async () => {
    const res = await userRequest.get(`/usuarios/find/${id}`, {
      headers: { token: accessToken },
    });
    setUsuario(res.data.data);
  };

  useEffect(() => {
    getUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title='Perfil usuario'>
      <Container>
        <Wrapper>
          {!verifyEmail && (
            <Alerta>
              Revisa tu casilla de correo para confirmar tu cuenta.
            </Alerta>
          )}
          <Title>
            <span>MI CUENTA</span>
          </Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Subtitle>Detalles de la cuenta</Subtitle>
            <Btn>
              <Link
                to='/perfil-edit'
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'initial',
                }}
              >
                <Edit style={{ fontSize: '15px', marginRight: '3px' }} />
                <Span>Editar</Span>
              </Link>
            </Btn>
          </div>
          <Details>
            <Input>
              <Span> Nombre y Apellidos:</Span> {usuario.name}{' '}
              {usuario.lastName}
            </Input>
            <Input>
              <Span> DNI:</Span> {usuario.dni}
            </Input>
          </Details>
          <Subtitle>Detalles de contacto</Subtitle>
          <Details>
            <Input>
              <Span> Email:</Span> {usuario.email}
            </Input>
            <Input>
              <Span> Telefono:</Span> {usuario.tel}
            </Input>
            <Input>
              <Span> Direccion:</Span> {usuario.address}
            </Input>
            <Input>
              <Span> Ciudad:</Span> {usuario.poblac}
            </Input>
            <Input>
              <Span> Provincia:</Span> {usuario.prov}
            </Input>
            <Input>
              <Span> Codigo Postal:</Span> {usuario.postalCod}
            </Input>
          </Details>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Profile;
