import { useEffect, useState } from 'react';

import { publicRequest } from 'config/clienteAxios';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ArrowBack,
  EmailOutlined,
  LockOutlined,
  PersonOutline,
  PhoneAndroid,
  RoomOutlined,
  CheckCircleOutline,
  ErrorOutline,
} from '@material-ui/icons';
import {
  Alerta,
  BoxInput,
  Button,
  Container,
  Error,
  Form,
  Icon,
  Input,
  Success,
  Title,
  Wrapper,
} from './RegisterStyles';

const Register = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState('');

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      dni: '',
      email: '',
      address: '',
      tel: '',
      city: '',
      prov: '',
      codPostal: '',
      password: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Solo se permiten letras para este campo.')
        .min(3, 'Tu nombre es demasiado corto.')
        .required('El campo es requerido.'),
      lastName: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Solo se permiten letras para este campo.')
        .min(3, 'Tu apellido es demasiado corto.')
        .required('El campo es requerido.'),
      dni: Yup.string()
        .min(8, 'El dni debe contener al menos 8 digitos.')
        .required('El campo es requerido'),
      email: Yup.string()
        .email('El email no es valido')
        .required('El campo es requerido.'),
      address: Yup.string()
        .min(3, 'Tu direccion es demasiado corta.')
        .max(50, 'Debe tener como máximo 50 caracteres.')
        .required('El campo es requerido.'),
      tel: Yup.string()
        .matches(/^[0-9\s]+$/, 'Solo se permiten numeros para este campo.')
        .min(9, 'El telefono debe contener al menos 9 digitos.')
        .required('El campo es requerido'),
      city: Yup.string().required('El campo es requerido.'),
      prov: Yup.string().required('El campo es requerido.'),
      codPostal: Yup.string()
        .matches(/^[0-9\s]+$/, 'Solo se permiten numeros para este campo.')
        .max(5, 'Debe tener como máximo 5 caracteres.')
        .required('El campo es requerido.'),
      password: Yup.string()
        .min(8, 'La contraseña debe contener al menos 8 caracteres')
        .required('El campo es requerido.'),
    }),
    onSubmit: (valores, { resetForm }) => {
      registerUser(valores);
      resetForm({ valores: '' }); // Limpiar el formulario
    },
  });

  const registerUser = (inputs) => {
    const register = async () => {
      const res = await publicRequest.post('/auth/registrarse', inputs);
      setSuccess(res.data.success);
      setError(res.data.success);
      setMsg(res.data.msg);
    };
    register();
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
        setError(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <Container>
      <Wrapper>
        <Link to='/iniciar-sesion' className='link'>
          <ArrowBack />
        </Link>
        <Title>Registrate ahora</Title>

        {success && (
          <Success>
            {' '}
            <CheckCircleOutline style={{ marginRight: '10px' }} /> {msg}
          </Success>
        )}
        {!error && (
          <Error>
            {' '}
            <ErrorOutline style={{ marginRight: '10px' }} /> {msg}
          </Error>
        )}

        <Form onSubmit={formik.handleSubmit}>
          <BoxInput>
            <Icon>
              <PersonOutline />
            </Icon>
            <Input
              type='text'
              name='name'
              placeholder='Nombre'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.name && formik.errors.name ? (
            <Alerta>{formik.errors.name}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <PersonOutline />
            </Icon>
            <Input
              type='text'
              name='lastName'
              placeholder='Apellidos'
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.lastName && formik.errors.lastName ? (
            <Alerta>{formik.errors.lastName}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <PersonOutline />
            </Icon>
            <Input
              type='text'
              name='dni'
              placeholder='Ingrese su DNI'
              value={formik.values.dni}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.dni && formik.errors.dni ? (
            <Alerta>{formik.errors.dni}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <EmailOutlined />
            </Icon>
            <Input
              type='email'
              name='email'
              placeholder='Correo electrónico'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.email && formik.errors.email ? (
            <Alerta>{formik.errors.email}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='address'
              placeholder='Dirección'
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.address && formik.errors.address ? (
            <Alerta>{formik.errors.address}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <PhoneAndroid />
            </Icon>
            <Input
              type='tel'
              name='tel'
              placeholder='Teléfono (cod. área + número)'
              value={formik.values.tel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.tel && formik.errors.tel ? (
            <Alerta>{formik.errors.tel}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='city'
              placeholder='Ciudad'
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.city && formik.errors.city ? (
            <Alerta>{formik.errors.city}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='prov'
              placeholder='Provincia'
              value={formik.values.prov}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.prov && formik.errors.prov ? (
            <Alerta>{formik.errors.prov}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='codPostal'
              placeholder='Código Postal'
              value={formik.values.codPostal}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.codPostal && formik.errors.codPostal ? (
            <Alerta>{formik.errors.codPostal}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <LockOutlined />
            </Icon>
            <Input
              type='password'
              name='password'
              placeholder='Contraseña'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.password && formik.errors.password ? (
            <Alerta>{formik.errors.password}</Alerta>
          ) : null}

          <Button type='submit'>Registrarme</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Register;
